/**
 * Module dependencies.
 */

import { formatAmount } from 'src/core/utils/assets';
import { useCryptoAsset } from 'src/api/entities/crypto-assets/hooks';
import { useDca } from 'src/context/dca';
import { useSettings } from 'src/context/settings';

/**
 * Export `useFormatAsset` hook.
 */

export function useFormatAsset() {
  const { asset } = useDca();
  const { globalSettings } = useSettings();
  const assetProps = useCryptoAsset(asset);

  return {
    formatAsset: (amount: number) => formatAmount(amount, globalSettings?.format, assetProps)
  };
}
