/**
 * Module dependencies.
 */

import { AlternateColumnsSection } from './alternate-columns';
import { AnimatedScrollBlocksSection } from './animated-scroll-blocks';
import { AppCenterListSection } from './app-center-list';
import { BlurredLinesSection } from './blurred-lines';
import { CardsCarouselSection } from './cards-carousel';
import { CardsRowsAssetTextSection } from './cards-rows-asset-text';
import { CardsSection } from './cards';
import { ContinueLessonSection } from './continue-lessons';
import { DcaCalculatorSection } from './dca-calculator';
import { DescriptionSection } from './description';
import { DisclaimerSection } from './disclaimer';
import { DoubleColumnStickySection } from './double-column-sticky';
import { FaqsSection } from './faqs';
import { HeadingRowsSection } from './heading-rows';
import { HeroFormSection } from './hero-form';
import { HeroSection } from './hero';
import { LearnCenterSection } from './learn-center';
import { LegalDetailsSection } from './legal/details';
import { LegalHeroSection } from './legal/hero';
import { LegalIndexSection } from './legal';
import { ListSection } from './lists/list';
import { LogosCarouselSection } from './logos-carousel';
import { Page } from 'src/api/entities/pages/page/types';
import { ProcessCardsSection } from './process-cards';
import { StoryCardsSection } from './story-cards';
import { SubscriptionSection } from './subscription';
import { TwoColumnsListSection } from './lists/two-columns-list';
import { TwoRowsSection } from './two-rows';
import { useTranslate } from 'src/context/i18n';
import dynamic from 'next/dynamic';

/**
 * `Rolodex` dynamic import.
 */

const Rolodex = dynamic(() => import('./rolodex').then(mod => mod.Rolodex));

/**
 * Export `SectionProps` type.
 */

export type SectionProps = Page['sections'][number];

/**
 * Export `Section` component.
 */

export function Section(props: SectionProps) {
  const { section, sectionType } = props;
  const { t } = useTranslate();

  switch (sectionType) {
    case 'rolodex_section':
      return <Rolodex {...section} />;

    case 'animated_scroll_blocks_section':
      return <AnimatedScrollBlocksSection {...section} />;

    case 'alternate_columns_section':
      return <AlternateColumnsSection {...section} />;

    case 'app_center_list_section':
      return <AppCenterListSection ctaLabel={t('appCenter.learnMore')} {...section} />;

    case 'blurred_lines_section':
      return <BlurredLinesSection {...section} />;

    case 'cards_rows_asset_text_section':
      return <CardsRowsAssetTextSection {...section} />;

    case 'cards_section':
      return <CardsSection {...section} />;

    case 'cards_carousel_section':
      return <CardsCarouselSection {...section} />;

    case 'dca_calculator_section':
      return <DcaCalculatorSection {...section} />;

    case 'description_section':
      return <DescriptionSection {...section} />;

    case 'disclaimer_section':
      return <DisclaimerSection {...section} />;

    case 'double_column_sticky_section':
      return <DoubleColumnStickySection {...section} />;

    case 'faqs_section':
      return <FaqsSection {...section} />;

    case 'heading_rows_section':
      return <HeadingRowsSection {...section} />;

    case 'hero_form_section':
      return <HeroFormSection {...section} />;

    case 'hero_section':
      return <HeroSection {...section} />;

    case 'lessons_continue_section':
      return <ContinueLessonSection {...section} courses={props.courses} />;

    case 'learn_center_section':
      return <LearnCenterSection {...section} />;

    case 'legal_details_section':
      return (
        <LegalDetailsSection
          {...section}
          backButtonLabel={t('actions.back')}
          updatedAt={`${t('labels.updatedAt')} ${section.updatedAt}`}
        />
      );

    case 'legal_hero_section':
      return <LegalHeroSection {...section} />;

    case 'legal_index_section':
      return <LegalIndexSection {...section} />;

    case 'list_section':
      return <ListSection {...section} />;

    case 'logos_carousel_section':
      return <LogosCarouselSection {...section} />;

    case 'process_cards_section':
      return <ProcessCardsSection {...section} />;

    case 'story_cards_section':
      return <StoryCardsSection {...section} />;

    case 'subscription_section':
      return <SubscriptionSection {...section} />;

    case 'two_columns_list_section':
      return <TwoColumnsListSection {...section} />;

    case 'two_rows_section':
      return <TwoRowsSection {...section} />;

    default:
      return null;
  }
}
