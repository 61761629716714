/**
 * Module dependencies.
 */

import { DesktopStoryCards } from './desktop';
import { MobileStoryCards } from './mobile';
import { Props } from './shared';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation, fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Introduction` styled component.
 */

const Introduction = styled(Text).attrs({ as: 'div', variant: 'heading4' })`
  ${headingStyles}

  margin-bottom: 56px;
  max-width: 480px;

  ${media.min.ms`
    margin-bottom: 88px;
  `}
`;

/**
 * `Conclusion` styled component.
 */

const Conclusion = styled(Text).attrs({ as: 'div', variant: 'heading4' })`
  ${headingStyles}

  margin-left: auto;
  margin-top: 56px;
  max-width: 736px;

  ${media.min.ms`
    margin-top: 88px;
  `}
`;

/**
 * `BottomLead` styled component.
 */

const BottomLead = styled(Text).attrs({ as: 'div', variant: 'heading2' })`
  ${headingStyles}

  margin-top: 56px;
  max-width: 480px;

  ${media.min.ms`
    margin-top: 88px;
  `}

  ${media.min.md`
    margin-left: 144px;
  `}
`;

/**
 * `entryAnimation` constant.
 */

const entryAnimation = blurFadeInTopAnimation(0.15);

/**
 * `StoryCardsSection` component.
 */

export const StoryCardsSection = ({ bottomLead, conclusion, introduction, items, ...sectionProps }: Props) => {
  const isDesktop = useBreakpoint('ms');
  const Cards = isDesktop ? DesktopStoryCards : MobileStoryCards;

  return (
    <Section {...sectionProps}>
      {!isEmpty(introduction) && (
        <motion.div {...entryAnimation}>
          <RawHtml element={Introduction}>{introduction}</RawHtml>
        </motion.div>
      )}

      <motion.div {...fadeInTopAnimation(0.15)}>
        <Cards items={items} />
      </motion.div>

      {!isEmpty(conclusion) && (
        <motion.div {...entryAnimation}>
          <RawHtml element={Conclusion}>{conclusion}</RawHtml>
        </motion.div>
      )}

      {!isEmpty(bottomLead) && (
        <motion.div {...entryAnimation}>
          <RawHtml element={BottomLead}>{bottomLead}</RawHtml>
        </motion.div>
      )}
    </Section>
  );
};
