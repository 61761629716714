/**
 * Module dependencies.
 */

import { Card, Props } from './shared';
import { SwiperContainer } from 'src/components/sections/process-cards/desktop';
import { SwiperSlide } from 'swiper/react';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * `StyledSwiperSlide` styled component.
 */

const StyledSwiperSlide = styled(SwiperSlide)`
  aspect-ratio: 1;
  width: calc((100% - 16px) / 3);

  ${media.max.md`
    min-width: 410px;
  `}
`;

/**
 * Export `DesktopStoryCards` component.
 */

export const DesktopStoryCards = ({ items }: Pick<Props, 'items'>) => {
  return (
    <SwiperContainer>
      {items.map(item => (
        <StyledSwiperSlide key={item.id}>
          <Card item={item} />
        </StyledSwiperSlide>
      ))}
    </SwiperContainer>
  );
};
