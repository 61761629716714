/**
 * Module dependencies.
 */

import 'react-popper-tooltip/dist/styles.css';
import { Config, usePopperTooltip } from 'react-popper-tooltip';
import { Transition, TransitionStatus } from 'react-transition-group';
import { transparentize } from 'src/styles/utils/colors';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Config & {
  children: ReactNode;
  className?: string;
  triggerElement: ReactNode;
};

/**
 * `TriggerElement` styled component.
 */

const TriggerElement = styled.span`
  cursor: pointer;
`;

/**
 * `TooltipElement` styled component.
 */

const TooltipElement = styled.div`
  background-color: ${transparentize('neutral100', 0.72)};
  border-radius: var(--border-radius-sm);
  box-shadow: var(--box-shadow-default);
  color: var(--color-neutral0);
  display: flex;
  flex-direction: column;
  opacity: 0;
  padding: 8px;
  transition: opacity var(--transition-default);
  white-space: normal;
  width: max-content;
  z-index: var(--z-index-tooltip);

  &[data-visible='true'] {
    opacity: 1;
  }

  &[data-visible='false'] {
    opacity: 0;
  }
`;

/**
 * Export `Tooltip` component.
 */

export const Tooltip = (props: Props) => {
  const { children, className, triggerElement, ...options } = props;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip(options);

  return (
    <>
      <TriggerElement className={className} ref={setTriggerRef}>
        {triggerElement}
      </TriggerElement>

      <Transition in={visible} mountOnEnter timeout={1000} unmountOnExit>
        {(state: TransitionStatus) => (
          <TooltipElement
            data-visible={['entering', 'entered'].includes(state)}
            ref={setTooltipRef}
            {...getTooltipProps()}
          >
            {children}
          </TooltipElement>
        )}
      </Transition>
    </>
  );
};
