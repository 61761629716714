/**
 * Module dependencies.
 */

import { BackLink } from 'src/components/core/links/back-link';
import { LegalDetailsSectionFragment } from 'src/api/entities/sections/legal-details/types';
import { MobileScrollSpyWrapper } from 'src/components/core/scroll-spy/mobile-scroll-spy';
import { RichText } from 'src/components/rich-text';
import { ScrollSpy } from 'src/components/core/scroll-spy';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useScrollSpy } from 'src/hooks/use-scroll-spy';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = LegalDetailsSectionFragment & {
  backButtonLabel: string;
  backButtonUrl: string;
};

/**
 * 'Grid' styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas: 'header' 'content' 'scrollspy';

  &[data-index='false'] {
    grid-template-columns: 1fr;
  }

  ${media.min.md`
    grid-template-areas: 'header scrollspy' 'content scrollspy';
    grid-template-columns: 1fr 384px;
    grid-column-gap: 96px;
  `}
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  display: grid;
  grid-row-gap: 32px;
  padding-bottom: 64px;

  ${media.max.md`
    grid-row-gap: 24px;
    padding-bottom: 48px;
  `}
`;

/**
 * 'TitleWrapper' styled component.
 */

const HeaderTitle = styled.div`
  display: grid;
  grid-row-gap: 12px;
`;

/**
 * `StyledScrollSpy` styled component.
 */

const StyledScrollSpy = styled(ScrollSpy)`
  grid-area: scrollspy;

  ${media.max.md`
    grid-area: mobilespy;
    padding: 0 0 16px;
  `}

  ${media.min.md`
    height: max-content;
    margin-right: var(--gutter-cards);
    max-height: calc(100vh - (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--bottom-banner-height) + var(--gutter-navbar-y) * 3) - 32px);
    overflow-y: auto;
    position: sticky;
    top: calc((var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3) + 50px);
    transition: 0.25s ease-out;
    transition-property: background-color, color, max-height;
    width: calc(100% - var(--gutter-cards));
  `}
`;

/**
 * 'StyledRichText' styled component.
 */

const StyledRichText = styled(RichText)`
  font-size: 20px;
  font-weight: 400;
  grid-area: content;
  letter-spacing: -0.01em;
  line-height: 26px;
  overflow-x: auto;

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 26px;
    padding: 24px 0;
    scroll-margin-top: calc(var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3);

    ${media.min.md`
      font-size: 24px;
      letter-spacing: -0.02em;
      line-height: 31.2px;
      padding: 32px 0 24px 0;
    `}
  }

  p {
    padding-bottom: 32px;

    ${media.max.md`
      font-size: 18px;
      line-height: 23.4px;

      br {
        content: '';
      }

      br::after {
        content: ', ';
        display: inline;
      }
    `}
  }

  colgroup {
    ${media.max.md`
      display:none;
    `}
  }

  td {
    font-size: 18px;
    line-height: 23.4px;

    ${media.max.md`
      &[has-nbsp="true"] {
        display: none;
      }

      min-width: 100%;
    `}
    
    ${media.min.md`
      padding: 24px 32px 24px 0;
    `}
  }

  table {
    border-collapse: collapse;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    overflow-x: scroll;
    table-layout: auto;

    th {
      color: var(--color-neutral50);
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      padding: 24px 0;
      text-align: left;
      vertical-align: top;
    }

    thead {
      ${media.min.md`
        display: table-header-group;
      `}
    }

    tbody {
      ${media.min.md`
        border-top: 1px solid rgb(235, 236, 245);
      `}
    }

    tr {
      margin-bottom: 16px;
      padding-bottom: 16px;
      text-align: left;
      vertical-align: top;

      &:not(.next-has-nbsp){
        border-bottom: 1px solid #EBECF5;
      }

      td:nth-child(1) {
        color: var(--color-neutral90);
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;

        ${media.max.md`
          margin-bottom: 8px;
        `}
      }

      :last-child {
        border-bottom: none;
      }

      td > p {
        color: var(--color-neutral70);
        padding-bottom: 8px;

        ${media.min.md`
          padding-bottom: 0;
        `}
      }
    }

    ${media.max.md`
      table, thead, tbody, th, td, tr {
        display: block;
      }

      thead tr {
        display: none;
      }

      thead + tbody td::before {
        color: var(--color-neutral50);
        content: attr(data-label);
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
        padding: 8px 0;
      }
    `}
  }

  ol {
    counter-reset: index;
    display: grid;
    padding: 0;

    ol {
      grid-column: span 2;

      ol {
        grid-column: 2;
      }
    }

    > li {
      display:grid;
      grid-column-gap: 8px;
      grid-template-columns: 48px 1fr;
      padding: 0;

      ::before {
        content: counters(index, '.') '. ';
        counter-increment: index;
        font-size: 18px;
        letter-spacing: -0.01em;
        line-height: 23.4px;

        ${media.min.md`
          font-size: 20px;
          letter-spacing: -0.02em;
          line-height: 26px;
        `}
      }

      &:has(> h2) {
        ::before{
          font-size: 20px;
          font-weight: 700;
          letter-spacing: -0.01em;
          line-height: 26px;
          padding: 24px 0;

          ${media.min.md`
            font-size: 24px;
            letter-spacing: -0.02em;
            line-height: 31.2px;
            padding: 32px 0 24px;
          `}
        }
      }

      :last-child {
        padding-bottom: 0;
      }

      > p:not(:first-child) {
        grid-column: span 2;

        ${media.min.md`
          grid-column: 2;
        `}
      }

      > ul {
        display: grid;
        grid-column: 2;
        grid-row-gap: 16px;
        padding: 0 0 32px;

        >li::before {
          content: url('/images/arrow-right.svg');
          margin-top:3px;
        }

        > li {
          display: grid;
          grid-column-gap: 8px;
          grid-template-columns: 20px 1fr;

          ${media.max.md`
            font-size: 18px;
            line-height: 23.4px;
          `}
        }
      }
    }
  }
`;

/**
 * Export `LegalDetailsSection` component.
 */

export function LegalDetailsSection(props: Props) {
  const { backButtonLabel, backButtonUrl, content, navigationList, theme, title, updatedAt } = props;
  const isDesktop = useBreakpoint('md');
  const { activeIndex, contentRef } = useScrollSpy<HTMLHeadingElement, HTMLDivElement>('h2');

  const renderScrollSpy = () => navigationList && (
    <StyledScrollSpy
      data-theme={theme}
      isNumeric
      selfIndex={activeIndex}
      siblingNodes={navigationList}
    />
  );

  return (
    <Section>
      <Grid data-index={!!navigationList} >
        <Header>
          {backButtonUrl && backButtonLabel && (
            <BackLink
              href={backButtonUrl}
              iconSize={isDesktop ? 24 : 20}
              variant={isDesktop ? 'label1' : 'label2'}
          >
              {backButtonLabel}
            </BackLink>
          )}

          <HeaderTitle>
            <Text as={'h1'} variant={'heading2'}>{title}</Text>

            <Text as={'h2'} fontWeight={400} variant={'subtitle2'}>
              {updatedAt}
            </Text>
          </HeaderTitle>
        </Header>

        <StyledRichText ref={contentRef}>
          {content}
        </StyledRichText>

        {isDesktop && renderScrollSpy()}
      </Grid>

      {navigationList && !isDesktop && (
        <MobileScrollSpyWrapper title={title}>
          {renderScrollSpy()}
        </MobileScrollSpyWrapper>
      )}
    </Section>
  );
}