/**
 * Module dependencies.
 */

import { Asset } from 'src/api/entities/asset/types';
import { CardWrapper } from './shared';
import { ComponentProps, useMemo } from 'react';
import { Image } from 'src/components/core/image';
import { Media, VideoFragment } from 'src/api/entities/media/types';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { useDeviceSource } from 'src/hooks/use-device-source';
import { useTranslate } from 'src/context/i18n';
import circleCheckIcon from 'src/assets/svgs/16/circle-check.svg';
import circleIcon from 'src/assets/svgs/16/circle.svg';
import clockIcon from 'src/assets/svgs/16/clock.svg';
import styled from 'styled-components';

/**
 * Export `LessonCardProps` type.
 */

export type LessonCardProps = ComponentProps<typeof CardWrapper> & {
  asset?: Media;
  duration: number;
  index: number;
  layout?: 'vertical' | 'horizontal';
  progress?: number;
  subtitle: string;
  tag?: string;
  thumbnail?: Asset;
  title: string;
};

/**
 * `StyledCardWrapper` styled component.
 */

const StyledCardWrapper = styled(CardWrapper)`
  --card-dim-color: var(--color-neutral70);
  --card-color: var(--color-neutral90);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: 100%;

  &[data-complete='true'] {
    --card-background-color: var(--color-primaryForLight05);
  }

  &[data-theme='dark'],
  [data-theme='dark'] & {
    --card-dim-color: var(--color-neutral10);

    &[data-complete='true'] {
      --card-background-color: var(--color-neutral95);
    }
  }

  &[data-layout='horizontal'] {
    grid-template-columns: 50% 50%;
    grid-template-rows: unset;
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  aspect-ratio: 16 / 9;
  position: relative;
`;

/**
 * `TagImageOverlay` styled component.
 */

const TagImageOverlay = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  inset: 0;
  position: absolute;
`;

/**
 * `Tag` styled component.
 */

const Tag = styled(Text).attrs({
  block: true,
  variant: 'paragraph3'
})`
  border: 1px solid var(--card-dim-color);
  border-radius: var(--border-radius-sm);
  color: var(--card-dim-color);
  inset: 16px auto auto 16px;
  padding: 2px 8px;
  position: absolute;
  z-index: 1;
`;

/**
 * `CardContent` styled component.
 */

const CardContent = styled.div`
  display: grid;
  gap: 16px;
  grid-template-areas:
    'index   duration'
    'content content';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  padding: 24px;
  position: relative;
`;

/**
 * `IconInfoWrapper` styled component.
 */

const IconInfoWrapper = styled.div`
  align-items: center;
  color: var(--card-dim-color);
  column-gap: 8px;
  display: flex;

  &[data-progress='100'] {
    color: var(--color-primaryForLight50);
  }

  &[data-progress='notStarted'] {
    color: var(--color-neutral50);
  }
`;

/**
 * `SmallText` styled component.
 */

const SmallText = styled(Text).attrs({
  block: true,
  variant: 'paragraph2'
})`
  -webkit-box-orient: vertical;
  color: var(--card-dim-color);
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  block: true,
  fontWeight: 700,
  variant: 'subtitle2'
})`
  -webkit-box-orient: vertical;
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-line-clamp: 2;
  margin-bottom: 8px;
  overflow: hidden;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-area: content;
  height: 100%;
  justify-content: space-between;
  position: absolute;
`;

/**
 * Export `LessonCardThumbnail` component.
 */

export const LessonCardThumbnail = (props: Pick<LessonCardProps, 'asset' | 'thumbnail' | 'title'>) => {
  const { asset, thumbnail, title } = props;
  const imageThumbnail = useDeviceSource([asset?.desktop?.asset, asset?.mobile?.asset], 'ms');
  const videoThumbnail = useDeviceSource(
    [(asset as VideoFragment)?.desktop?.thumbnail, (asset as VideoFragment)?.mobile?.thumbnail],
    'ms'
  );

  const commonProps = {
    fill: true,
    style: { objectFit: 'cover' as const }
  };

  if (thumbnail) {
    return <Image alt={thumbnail?.alt ?? title} src={thumbnail?.url ?? ''} {...commonProps} />;
  }

  if (asset?.type === 'image' && imageThumbnail) {
    return <Image alt={imageThumbnail.alt ?? title} src={imageThumbnail.url} {...commonProps} />;
  }

  if (asset?.type === 'video' && videoThumbnail) {
    return <Image alt={videoThumbnail.alt ?? title} src={videoThumbnail.url} {...commonProps} />;
  }

  return <Image alt={title} src={'/images/lesson-thumbnail.png'} {...commonProps} />;
};

/**
 * Export `LessonCard` component.
 */

export const LessonCard = (props: LessonCardProps) => {
  const { language, t } = useTranslate();
  const { asset, duration, index, layout = 'vertical', progress, subtitle, tag, thumbnail, title, ...rest } = props;

  const formatedDuration = useMemo(() => {
    const formatter = new Intl.NumberFormat(language, {
      style: 'unit',
      unit: 'minute',
      unitDisplay: 'short'
    });

    return formatter.format(duration);
  }, [duration, language]);

  const progressStatus = useMemo(() => {
    if (progress === undefined) {
      return t('lesson.notStarted');
    }

    return `${!(progress >= 1) ? `${Math.round(progress * 100)}% ` : ''}${t('lesson.completed')}`;
  }, [progress, t]);

  return (
    <StyledCardWrapper data-complete={progress && progress >= 1} data-layout={layout} {...rest}>
      <ImageWrapper>
        <LessonCardThumbnail asset={asset} thumbnail={thumbnail} title={title} />

        {tag && (
          <>
            <Tag>{tag}</Tag>

            <TagImageOverlay />
          </>
        )}
      </ImageWrapper>

      <CardContent>
        <SmallText style={{ gridArea: 'index' }}>{`${t(`lesson.lesson`)} ${index}`}</SmallText>

        <IconInfoWrapper>
          <Svg icon={clockIcon} size={'12px'} />

          <SmallText style={{ gridArea: 'duration' }}>{formatedDuration}</SmallText>
        </IconInfoWrapper>

        <Content>
          <div>
            <Title>{title}</Title>

            <SmallText>{subtitle}</SmallText>
          </div>

          <IconInfoWrapper data-progress={progress ?? 'notStarted'}>
            <Svg icon={progress && progress >= 1 ? circleCheckIcon : circleIcon} size={'12px'} />

            <Text block variant={'paragraph2'}>
              {progressStatus}
            </Text>
          </IconInfoWrapper>
        </Content>
      </CardContent>
    </StyledCardWrapper>
  );
};
