/**
 * Module dependencies.
 */

import { formatMonthLong, formatMonthNumeric, formatMonthShort, formatPercentage } from 'src/core/utils/format';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `useFormat` hook.
 */

export function useFormat() {
  const { language } = useTranslate();

  return {
    formatMonthLong: (date: Date | number | string) => formatMonthLong(date, language),
    formatMonthNumeric: (date: Date | number | string) => formatMonthNumeric(date, language),
    formatMonthShort: (date: Date | number | string) => formatMonthShort(date, language),
    formatPercentage: (value: number) => formatPercentage(value, language)
  };
}
