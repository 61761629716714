/**
 * Module dependencies.
 */

import { PropsWithChildren } from 'react';
import { RichText } from 'src/components/rich-text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = PropsWithChildren<{
  textAlign?: 'center' | 'left' | 'right';
}>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.div)`
  ${media.max.ms`
    padding-top: 16px;
  `}

  ${media.min.ms`
    padding: 32px 24px 0;
  `}
`;

/**
 * `StyledRichText` styled component.
 */

const StyledRichText = styled(RichText)`
  ${textStyles.small}

  color: var(--color-neutral50);
  font-weight: 400;

  [data-theme='dark'] & {
    color: var(--color-neutral40);
  }
`;

/**
 * Export `SectionFootnote` component.
 */

export function SectionFootnote({ children, textAlign }: Props) {
  return (
    <Wrapper {...blurFadeInTopAnimation()} style={{ textAlign }}>
      <StyledRichText>{children}</StyledRichText>
    </Wrapper>
  );
}
