/**
 * Module dependencies.
 */

import { buttonThemesConfig as cardButtonThemesConfig } from './card-button-theme-config';
import merge from 'lodash/merge';

/**
 * `buttonThemesConfig` constant.
 */

const buttonThemesConfig = merge(cardButtonThemesConfig, {
  dark: {
    ghost: {
      active: {
        backgroundColor: 'var(--color-neutral70)',
        textColor: 'var(--color-neutral0)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral90)',
        textColor: 'var(--color-neutral50)'
      },
      hover: {
        backgroundColor: 'var(--color-neutral80)',
        textColor: 'var(--color-neutral0)'
      },
      idle: {
        backgroundColor: 'transparent',
        textColor: 'var(--color-neutral0)'
      }
    },
    neutral: {
      active: {
        textColor: 'var(--color-neutral0)'
      },
      hover: {
        textColor: 'var(--color-neutral0)'
      },
      idle: {
        textColor: 'var(--color-neutral0)'
      }
    }
  },
  light: {
    ghost: {
      active: {
        backgroundColor: 'var(--color-neutral30)',
        textColor: 'var(--color-neutral100)'
      },
      disabled: {
        backgroundColor: 'var(--color-neutral10)',
        textColor: 'var(--color-neutral50)'
      },
      hover: {
        backgroundColor: 'var(--color-neutral20)',
        textColor: 'var(--color-neutral100)'
      },
      idle: {
        backgroundColor: 'transparent',
        textColor: 'var(--color-neutral100)'
      }
    },
    neutral: {
      active: {
        textColor: 'var(--color-neutral90)'
      },
      hover: {
        textColor: 'var(--color-neutral90)'
      },
      idle: {
        textColor: 'var(--color-neutral90)'
      }
    }
  }
});

/**
 * `buttonBackdropBlurConfig` constant.
 */

const buttonBackdropBlurConfig = {
  ghost: 0,
  neutral: 15,
  primary: 15
};

/**
 * Export `buttonThemes` constant.
 */

export const buttonThemes = (['neutral', 'primary', 'ghost'] as const).map(
  variant => `
    &[data-variant='${variant}'] {
      --button-background-color: ${buttonThemesConfig.light[variant].idle.backgroundColor};
      --button-text-color: ${buttonThemesConfig.light[variant].idle.textColor};
      --button-active-background-color: ${buttonThemesConfig.light[variant].active.backgroundColor};
      --button-active-text-color: ${buttonThemesConfig.light[variant].active.textColor};
      --button-hover-background-color: ${buttonThemesConfig.light[variant].hover.backgroundColor};
      --button-hover-text-color: ${buttonThemesConfig.light[variant].hover.textColor};
      --button-disabled-background-color: ${buttonThemesConfig.light[variant].disabled.backgroundColor};
      --button-disabled-text-color: ${buttonThemesConfig.light[variant].disabled.textColor};
      --button-backdrop-blur: ${buttonBackdropBlurConfig[variant]}px;
    }

    [data-theme='dark'] &[data-variant='${variant}'] {
      --button-background-color: ${buttonThemesConfig.dark[variant].idle.backgroundColor};
      --button-text-color: ${buttonThemesConfig.dark[variant].idle.textColor};
      --button-active-background-color: ${buttonThemesConfig.dark[variant].active.backgroundColor};
      --button-active-text-color: ${buttonThemesConfig.dark[variant].active.textColor};
      --button-hover-background-color: ${buttonThemesConfig.dark[variant].hover.backgroundColor};
      --button-hover-text-color: ${buttonThemesConfig.dark[variant].hover.textColor};
      --button-disabled-background-color: ${buttonThemesConfig.dark[variant].disabled.backgroundColor};
      --button-disabled-text-color: ${buttonThemesConfig.dark[variant].disabled.textColor};
    }
  `
);
