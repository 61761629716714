/**
 * Module dependencies.
 */

import { Country } from './types';
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { getCountries } from './get';

/**
 * Export `CountriesQueryOptions` type.
 */

export type CountriesQueryOptions = Partial<DefinedInitialDataOptions<Country[]>>;

/**
 * Export `countriesQueryKey` constant.
 */

export const countriesQueryKey = ['countries'];

/**
 * Export `useCountries` query hook.
 */

export function useCountries(options?: CountriesQueryOptions) {
  return useQuery({
    queryFn: getCountries,
    queryKey: countriesQueryKey,
    ...options
  });
}
