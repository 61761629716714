/**
 * Module dependencies.
 */

import 'swiper/css';
import { CardAsset, CardText, CardTextWrapper, CardWrapper, Props } from './shared';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fadeInTopAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { useIsomorphicLayoutEffect } from 'framer-motion';
import { useRef, useState } from 'react';
import styled from 'styled-components';

/**
 * Export `SwiperContainer` styled component.
 */

export const SwiperContainer = styled(Swiper).attrs({
  direction: 'horizontal',
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 8
})`
  --swiper-gradient-width: calc(var(--gutter) + var(--gutter-cards));

  left: calc(var(--swiper-gradient-width) * -1);
  margin: 0 var(--gutter-cards);
  padding: 0 var(--swiper-gradient-width);
  width: calc(100% + (var(--swiper-gradient-width) - var(--gutter-cards)) * 2);

  ::before,
  ::after {
    background: linear-gradient(90deg, var(--color-background) 4%, transparent 100%);
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: var(--swiper-gradient-width);
    z-index: 2;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(180deg);
  }

  ${media.min.xl`
    --swiper-gradient-width: 90px;
    --swiper-container-edge-size: calc(((100vw - min(100vw, var(--container-width))) / 2) + var(--gutter));
  `}
`;

/**
 * `StyledSwiperSlide` styled component.
 */

const StyledSwiperSlide = styled(SwiperSlide)`
  aspect-ratio: 2 / 3;
  width: calc((100% - 16px) / 3);

  ${media.max.md`
    min-width: 410px;
  `}
`;

/**
 * Export `DesktopProcessCards` component.
 */

export const DesktopProcessCards = ({ items }: Pick<Props, 'items'>) => {
  const textRefs = useRef<HTMLElement[]>([]);
  const [textMaxHeight, setTextMaxHeight] = useState<number>();

  useIsomorphicLayoutEffect(() => {
    let max = 0;

    textRefs.current.forEach(element => {
      const height = element.getBoundingClientRect().height;

      if (height > max) {
        max = height;
      }
    });

    setTextMaxHeight(max);
  }, [items]);

  return (
    <SwiperContainer>
      {items.map((item, index) => (
        <StyledSwiperSlide key={item.id}>
          <CardWrapper {...fadeInTopAnimation(0.15 * index)}>
            <CardAsset {...item} />

            <CardTextWrapper
              ref={element => {
                if (element) {
                  textRefs.current[index] = element;
                }
              }}
              {...(textMaxHeight && { style: { height: textMaxHeight } })}
            >
              <CardText index={index} item={item} />
            </CardTextWrapper>
          </CardWrapper>
        </StyledSwiperSlide>
      ))}
    </SwiperContainer>
  );
};
