/**
 * Module dependencies.
 */

import { AssetOption, AssetValue } from './shared';
import { AssetProps } from 'src/core/utils/assets';
import { FormGroup } from 'src/components/core/forms/form-group';
import { Input } from 'src/components/core/forms/input';
import { Select } from 'src/components/core/forms/select';
import { formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import { useDca } from 'src/context/dca';
import { useElementDimensions } from 'src/hooks/use-element-dimensions';
import { useMemo } from 'react';
import { useTranslate } from 'src/context/i18n';
import styled from 'styled-components';

/**
 * Export `CurrencyFieldProps` type.
 */

export type CurrencyFieldProps = {
  helpText?: string;
  label: string;
  options: Pick<AssetProps, 'code' | 'image' | 'name'>[];
};

/**
 * `FormControl` styled component.
 */

const FormControl = styled.div`
  ${formControlStyles}
  ${formControlVariants.default}

  display: grid;
  grid-area: input;
  grid-template-columns: 3fr 136px;
  padding: 0;
  position: relative;
`;

/**
 * `NumberInput` styled component.
 */

const NumberInput = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
    padding-right: 0;
  }
`;

/**
 * Export `DcaFormCurrencyField` component.
 */

export const DcaFormCurrencyField = (props: CurrencyFieldProps) => {
  const { t } = useTranslate();
  const { currency, initialAmount, updateDcaState } = useDca();
  const options = useMemo(
    () =>
      props.options.map(({ code, name, ...props }) => {
        new Image().src = props.image;

        return {
          label: name,
          props,
          value: code
        };
      }),
    [props.options]
  );

  const [formControlRef, { width }] = useElementDimensions<HTMLDivElement>();

  return (
    <FormGroup
      error={initialAmount === '' ? t('form.errors.validValue') : undefined}
      helpText={props.helpText}
      id={'currency'}
      label={props.label}
    >
      <FormControl ref={formControlRef}>
        <NumberInput
          aria-label={'currency amount'}
          id={'currencyAmount'}
          inputMode={'decimal'}
          onChange={({ target }) => updateDcaState({ initialAmount: Number(target.value.replace(/,/g, '.')) || '' })}
          type={'number'}
          value={initialAmount}
          variant={'ghost'}
        />

        <Select
          components={{
            Option: AssetOption,
            Value: AssetValue
          }}
          filterPlaceholder={t('actions.search')}
          id={'currencyType'}
          isFilterable
          onChange={({ value }) => updateDcaState({ currency: value as string })}
          options={options}
          style={{ position: 'static' }}
          value={options.find(({ value }) => value === currency)}
          variant={'ghost'}
          virtualized
          width={width}
        />
      </FormControl>
    </FormGroup>
  );
};
