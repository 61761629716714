/**
 * Module dependencies.
 */

import { CountrySubdivision } from './types';
import { axiosUpholdInstance } from 'src/api/lib/uphold';

/**
 * Export `Params` type
 */

export type Params = {
  country?: string;
};

/**
 * Export `getCountrySubdivisions` request.
 */

export const getCountrySubdivisions = async (params: Params): Promise<CountrySubdivision[]> => {
  if (!params.country) {
    return [];
  }

  const response = await axiosUpholdInstance.get(`countries/${params.country}/subdivisions`);

  return response.data;
};
