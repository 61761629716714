/**
 * Module dependencies.
 */
import { Button } from 'src/components/core/buttons/button';
import { LESSON_PROGRESS_KEY, LessonProgress } from 'src/types/local-storage';
import { LearnCenterSectionFragment } from 'src/api/entities/sections/learn-center/types';
import { LessonCard } from 'src/components/core/card/lesson-card';
import { RouterLink } from 'src/components/core/links/router-link';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { textStyles } from 'src/styles/typography';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useMemo } from 'react';
import { useIsClient } from 'src/context/client';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/context/i18n';
import styled from 'styled-components';
import template from 'lodash/template';

/**
 * `NormalizedLesson` type.
 */

type NormalizedLesson = LearnCenterSectionFragment['courses'][0]['lessons'][0] & { progress: number | undefined };

/**
 * `templateOptions` constant.
 */

const templateOptions = { interpolate: /{{(\w*)}}/g };

/**
 * `CoursesWrapper` styled component.
 */

const CoursesWrapper = styled.div`
  display: grid;
  gap: 96px;

  ${media.min.ms`
    gap: 200px;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  block: true,
  fontWeight: 400,
  variant: 'heading3'
})`
  ${media.min.ms`
    text-align: center;

    ${textStyles.subtitle1}
  `}
`;

/**
 * `LessonsWrapper` styled component.
 */

const LessonsWrapper = styled.div`
  display: grid;
  gap: 8px;
  margin: 0 var(--gutter-cards) 40px;

  ${media.min.ms`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-bottom: 52px;
  `}

  ${fadedCards};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({
  block: true,
  fontWeight: 400,
  variant: 'subtitle2'
})`
  margin: 16px auto 48px;
  max-width: 961px;

  ${media.min.ms`
    margin-bottom: 88px;
    text-align: center;
  `}
`;

/**
 * `CourseButton` styled component.
 */

const CourseButton = styled(Button)`
  display: flex;
  margin: 0 auto;
`;

/**
 * `LessonCardWrapper` styled component.
 */

const LessonCardWrapper = styled.div`
  margin: 0 auto;
  min-height: 472px;
  width: min(456px, 100%);

  ${media.min.ms`
    margin: 0;
    min-height: unset;
    width: unset;

    &[data-lessons-number='2'] {
      min-height: 520px;
    }

    &[data-lessons-number='3'] {
      min-height: 472px;
    }
  `}

  ${media.min.md`
    &[data-lessons-number='2'] {
      min-height: 620px;
    }

    &[data-lessons-number='3'] {
      min-height: 520px;
    }
  `}
`;

/**
 * Export `LearnCenterSection` component.
 */

export const LearnCenterSection = ({ courses, pretitle, title, ...rest }: LearnCenterSectionFragment) => {
  const isMobile = !useBreakpoint('ms');
  const router = useRouter();
  const { t } = useTranslate();
  const isDesktop = useBreakpoint('ms');
  const isClient = useIsClient();
  const [store] = useLocalStorage<LessonProgress>(LESSON_PROGRESS_KEY);
  const seeAllLabel = useMemo(() => template(t('lesson.seeAllLessons'), templateOptions), [t]);
  const normalizedCourses = useMemo(
    () =>
      courses.map(course => {
        const normalizedLessons = course.lessons.map(lesson => ({
          ...lesson,
          progress: store?.[course.id]?.[lesson.id]?.[0]
        }));

        return {
          ...course,
          displayLessons: normalizedLessons.reduce((acc, lesson, index) => {
            if (index === 0) {
              return normalizedLessons.slice(0, 3);
            }

            if ((acc[0].progress ?? 0) >= 1 && (lesson.progress === undefined || lesson.progress < 1)) {
              return normalizedLessons.slice(index, index + 3);
            }

            return acc;
          }, [] as NormalizedLesson[])
        };
      }),
    [courses, store]
  );

  const getLearnUrl = useCallback(
    (course: string, lesson?: string) => {
      if (!router.isReady) {
        return '#';
      }

      return `${router.asPath.split(/[?#]/)[0]}/${course}${lesson ? `/${lesson}` : ''}`.replace(/\/{2,}/g, '/');
    },
    [router]
  );

  return (
    <Section {...rest} theme={'light'} title={title} {...(isDesktop && { pretitle })}>
      <CoursesWrapper>
        {normalizedCourses.map(course => (
          <div key={course.id}>
            <Title>{course.title}</Title>

            <Description>{course.description}</Description>

            <LessonsWrapper>
              {course.displayLessons.map(lesson => (
                <LessonCardWrapper data-lessons-number={course.displayLessons.length} key={lesson.id}>
                  <RouterLink href={getLearnUrl(course.slug, lesson.slug)}>
                    <LessonCard
                      asset={lesson.asset}
                      data-grid-card
                      description={lesson.title}
                      duration={lesson.duration}
                      index={lesson.index}
                      key={lesson.id}
                      layout={course.displayLessons.length === 1 && !isMobile ? 'horizontal' : 'vertical'}
                      progress={store?.[course.id]?.[lesson.id]?.[0]}
                      slug={lesson.slug}
                      subtitle={lesson.description}
                      thumbnail={lesson.thumbnail}
                      title={lesson.title}
                    />
                  </RouterLink>
                </LessonCardWrapper>
              ))}
            </LessonsWrapper>

            <CourseButton hasLinkIcon href={getLearnUrl(course.slug)} variant={'ghost'}>
              {isClient ? seeAllLabel({ number: course.lessons.length > 1 ? course.lessons.length : '' }) : course.slug}
            </CourseButton>
          </div>
        ))}
      </CoursesWrapper>
    </Section>
  );
};
