/**
 * Module dependencies.
 */

import { Svg } from 'src/components/core/svg';
import exclamationCircleIcon from 'src/assets/svgs/24/exclamation-circle.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  message: string;
  type?: 'error';
};

/**
 * `colors` constant.
 */

const colors = {
  error: 'var(--color-critical50)'
};

/**
 * `icons` constant.
 */

const icons = {
  error: exclamationCircleIcon
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --alert-background-color: var(--color-neutral05);
  --alert-color: var(--color-neutral90);

  align-items: center;
  background-color: var(--alert-background-color);
  border-radius: inherit;
  color: var(--alert-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
  inset: 0;
  justify-content: center;
  padding: 16px;
  position: absolute;
  text-align: center;

  [data-theme='dark'] & {
    --alert-background-color: var(--color-neutral95);
    --alert-color: var(--color-neutral0);
  }

  > span {
    margin: 0 auto;
    max-width: 368px;
  }
`;

/**
 * Export `Alert` component.
 */

export const Alert = ({ message, type = 'error' }: Props) => {
  return (
    <Wrapper>
      <Svg color={colors[type]} icon={icons[type]} size={'24px'} />

      <span>{message}</span>
    </Wrapper>
  );
};
