/**
 * Module dependencies.
 */

import axios from 'redaxios';

/**
 * Export `axiosUpholdInstance`.
 */

export const axiosUpholdInstance = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_UPHOLD_API_URL_PROXY === 'true' ? '/api-uphold' : process.env.NEXT_PUBLIC_UPHOLD_API_URL
});
