/**
 * Module dependencies
 */

import { Media } from 'src/components/media';
import { Media as MediaType } from 'src/api/entities/media/types';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { TwoRowsSectionFragment } from 'src/api/entities/sections/two-rows/types';
import { blurFadeInTopAnimation, fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import styled, { css } from 'styled-components';

/**
 * `RowProps` type.
 */

type RowProps = {
  description?: string;
  media?: MediaType;
  note?: string;
  title?: string;
};

/**
 * Export `TwoRowsSectionProps` type.
 */

export type TwoRowsSectionProps = TwoRowsSectionFragment;

/**
 * `Row` styled component.
 */

const Row = styled.div`
  color: var(--color-text);
  text-align: left;

  ${media.min.ms`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 144px;

    & + & {
      gap: 88px;
      grid-template-columns: max-content auto;
      padding-top: 88px;
    }
  `}

  b, strong {
    color: var(--color-primary);
    font-weight: 500;
  }
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ fontWeight: 500, variant: 'heading1' })`
  ${headingStyles}

  width: fit-content;
`;

/**
 * `TextContainer` styled component.
 */

const TextContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'div', fontWeight: 500 })<{ hasVideo: boolean }>`
  ${headingStyles}

  ${ifProp(
    'hasVideo',
    css`
      ${textStyles.heading3}
    `,
    css`
      ${textStyles.heading4}

      ${media.max.ms`
        padding-top: 32px;
      `}
    `
  )}
`;

/**
 * `Note` styled component.
 */

const Note = styled(Text).attrs({ as: 'div', fontWeight: 500, variant: 'subtitle2' })`
  ${headingStyles}

  padding-top: 24px;
`;

/**
 * `MediaWrapper` styled component.
 */

const MediaWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  ${media.min.ms`
    min-height: 424px;
  `};

  ${media.max.ms`
    margin: 56px var(--gutter-cards);
    padding-top: 40px;
  `};
`;

/*
 * `Row`component.
 */

function RowContainer({ description, media, note, title }: RowProps) {
  return (
    <Row>
      {title && (
        <motion.div {...blurFadeInTopAnimation()}>
          <RawHtml element={Title}>{title}</RawHtml>
        </motion.div>
      )}

      {(description || note) && (
        <TextContainer {...blurFadeInTopAnimation(0.15)} {...(!!media && { style: { alignSelf: 'center' } })}>
          {description && (
            <Description hasVideo={!!media}>
              <RawHtml>{description}</RawHtml>
            </Description>
          )}

          {note && <RawHtml element={Note}>{note}</RawHtml>}
        </TextContainer>
      )}

      {!!media && (
        <MediaWrapper {...fadeInTopAnimation(0.15)}>
          <Media
            media={media}
            style={{
              borderRadius: '30px',
              height: 'auto',
              position: 'static',
              width: '100%'
            }}
          />
        </MediaWrapper>
      )}
    </Row>
  );
}

/**
 * Export `TwoRowsSection` component.
 */

export function TwoRowsSection({ rows, ...sectionProps }: TwoRowsSectionProps) {
  return (
    <Section {...sectionProps}>
      {rows.map((row, index) => (
        <RowContainer key={index} {...row} />
      ))}
    </Section>
  );
}
