/**
 * Module dependencies.
 */

import { CardFragment } from 'src/api/entities/sections/cards/types';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { linkBaseStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import React, { ComponentPropsWithoutRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = Omit<ComponentPropsWithoutRef<'div'>, 'content'> &
  Omit<CardFragment, 'id'> & {
    shrinkFactor?: number;
  };

/**
 * Export `TextContentProps` type.
 */

export type TextContentProps = ComponentPropsWithoutRef<'div'> & Partial<Record<'body' | 'subtitle' | 'title', string>>;

/**
 * Export `getWrapperProps` util.
 */

export const getWrapperProps = (props: object): ComponentPropsWithoutRef<'div'> =>
  omit(props, ['layout', 'expanded', 'content', 'cta', 'shrinkFactor']);

/**
 * Export `ExpandableWrapper` styled component.
 */

export const ExpandableWrapper = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  overflow: visible;
  position: relative;
  transition: grid-template-rows var(--transition-default);

  &::after {
    background: linear-gradient(0deg, var(--card-background-color), transparent);
    bottom: 100%;
    content: '';
    height: 256px;
    opacity: 0;
    position: absolute;
    transition: opacity var(--transition-default);
    width: 100%;
    z-index: 1;
  }

  [aria-expanded='true'] & {
    &::after {
      opacity: 1;
    }
  }
`;

/**
 * Export `CardWrapper` styled component.
 */

export const CardWrapper = styled.div`
  --card-background-color: var(--color-neutral0);
  --card-color: var(--color-neutral70);
  --color-primary: var(--color-primaryForLight);

  [data-theme='dark'] &[data-theme='inherit'],
  [data-theme='dark'] &:not([data-theme]),
  &[data-theme='dark'] {
    --card-background-color: var(--color-neutral95);
    --card-color: var(--color-neutral0);
    --color-primary: var(--color-primaryForDark);
  }

  background-color: var(--card-background-color);
  border-radius: var(--border-radius);
  color: var(--card-color);
  overflow: hidden;
  position: relative;

  &[data-height='full'] {
    height: 100%;
  }

  &[aria-expanded='true'] > ${ExpandableWrapper} {
    grid-template-rows: 1fr;
  }
`;

/**
 * Export `CardContent` styled component.
 */

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;

  ${media.min.md`
    padding: 32px;
  `}

  [data-expandable='true'] & {
    padding-bottom: 80px;

    ${media.min.md`
      padding-bottom: 96px;
    `}
  }
`;

/**
 * `TextContentWrapper` styled component.
 */

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ fontWeight: 700, variant: 'heading4' })`
  ${headingStyles}
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Text).attrs({ fontWeight: 400, variant: 'subtitle1' })`
  ${headingStyles}
`;

/**
 * `Body` styled component.
 */

const Body = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'paragraph1' })`
  ${headingStyles}

  a {
    ${linkBaseStyles}

    color: var(--color-primary);
  }
`;

/**
 * Export `TextContent` component.
 */

export const TextContent = (props: TextContentProps) => {
  const { body, subtitle, title, ...rest } = props;

  return (
    [title, subtitle, body].some(text => text?.length) && (
      <TextContentWrapper {...rest}>
        {!isEmpty(title) && <RawHtml element={Title}>{title}</RawHtml>}

        {!isEmpty(subtitle) && <RawHtml element={Subtitle}>{subtitle}</RawHtml>}

        {!isEmpty(body) && <RawHtml element={Body}>{body}</RawHtml>}
      </TextContentWrapper>
    )
  );
};
