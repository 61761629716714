/**
 * Module dependencies.
 */

import { useCallback, useRef, useState } from 'react';

/**
 * `Status` type.
 */

type Status = 'inview-from-below' | 'inview-from-above' | 'outview-above' | 'outview-below';

/**
 * `initialState` constant.
 */

const initialState = { ratio: 0, y: 0 };

/**
 * `PreviousState` type.
 */

type PreviousState = typeof initialState;

/**
 * `getViewportStatus` function.
 */

function getViewportStatus(entry: IntersectionObserverEntry, previous: PreviousState): Status {
  const { boundingClientRect, intersectionRatio, isIntersecting } = entry;
  const currentY = boundingClientRect.y;
  const currentRatio = intersectionRatio;

  if (previous.y === 0 && previous.ratio === 0) {
    if (isIntersecting) {
      return 'inview-from-below';
    }

    return entry.boundingClientRect.top > 0 ? 'outview-below' : 'outview-above';
  }

  const directionUp = currentY < previous.y;
  const ratioIncreasing = currentRatio > previous.ratio;

  if (directionUp) {
    return ratioIncreasing && isIntersecting ? 'inview-from-below' : 'outview-above';
  }

  return !ratioIncreasing && !isIntersecting ? 'outview-below' : 'inview-from-above';
}

/**
 * Export `useViewportObserver` hook.
 */

export function useViewportObserver<T extends HTMLElement>(
  options: IntersectionObserverInit = {}
): [(node: T) => void, Status] {
  const { root = null, rootMargin = '0px', threshold = 1 } = options;
  const [status, setStatus] = useState<Status>('outview-below');
  const previousObserver = useRef<IntersectionObserver | null>(null);
  const previous = useRef<PreviousState>(initialState);
  const handleIntersection = useCallback(([entry]: IntersectionObserverEntry[]) => {
    if (!entry) {
      return;
    }

    const status = getViewportStatus(entry, previous.current);

    // Only after getting the state, we update the previous values.
    previous.current = {
      ratio: entry.intersectionRatio,
      y: entry.boundingClientRect.y
    };

    setStatus(status);
  }, []);

  const ref = useCallback(
    (node: T) => {
      if (previousObserver.current) {
        previousObserver.current.disconnect();
        previousObserver.current = null;
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const observer = new IntersectionObserver(handleIntersection, { root, rootMargin, threshold });

        observer.observe(node);

        previousObserver.current = observer;
      }
    },
    [handleIntersection, root, rootMargin, threshold]
  );

  return [ref, status];
}
