/**
 * Module dependencies.
 */

import { Link, linkAnimatedStyles } from '.';
import { Props as LinkProps } from './router-link';
import { PropsWithChildren } from 'react';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import backIcon from 'src/assets/svgs/20/chevron-left.svg';
import styled from 'styled-components';

/**
 * `Props` component.
 */

type Props = PropsWithChildren<LinkProps> & {
  fontWeight?: 400 | 500 | 700;
  href?: string;
  iconSize?: number;
  variant?: 'label1' | 'label2';
};

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled(Link)`
  align-items: center;
  display: flex;
  gap: 8px;
  width: max-content;

  & > ${Text} {
    ${linkAnimatedStyles}
  }

  :hover {
    & > ${Text} {
      background-size: 100% 1px;
      opacity: 1;
    }
  }
`;

/**
 * Export `BackLink` component.
 */

export const BackLink = (props: Props) => {
  const { children, fontWeight, href, iconSize, style, variant } = props;

  return (
    <BackLinkWrapper href={href} style={style}>
      <Svg icon={backIcon} size={`${iconSize ?? 20}px`} />

      <Text fontWeight={fontWeight ?? 400} variant={variant ?? 'label1'}>
        {children}
      </Text>
    </BackLinkWrapper>
  )
};
