/**
 * Module dependencies.
 */

import { MutableRefObject, useEffect, useRef, useState } from 'react';

/**
 * Export `useCardGridResize` hook.
 */

export function useCardGridResize(): [number, MutableRefObject<HTMLDivElement>] {
  const ref = useRef<HTMLDivElement>(null!);
  const [shrinkFactor, setShrinkFactor] = useState(1);

  useEffect(() => {
    const onResize = () => {
      if (!ref.current || !ref.current.parentElement) {
        return;
      }

      const parentMaxWidth = Number(getComputedStyle(ref.current?.parentElement).maxWidth.replace('px', ''));

      if (isNaN(parentMaxWidth)) {
        return;
      }

      const factor = Math.min(1, window.innerWidth / parentMaxWidth);
      setShrinkFactor(factor);
    };

    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return [shrinkFactor, ref];
}
