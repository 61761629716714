/**
 * Module dependencies.
 */

import { AnimatedScrollBlocksSectionFragment } from 'src/api/entities/sections/animated-scroll-blocks/types';
import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import { Media } from 'src/components/media';
import {
  MotionValue,
  easeInOut,
  motion,
  useMotionTemplate,
  useScroll,
  useTransform,
  useWillChange
} from 'framer-motion';

import { RichTextClean } from 'src/components/rich-text/clean';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { breakpoints } from 'src/styles/breakpoints';
import { headingStyles } from 'src/components/core/layout/headings';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { textStyles } from 'src/styles/typography';
import { useMediaQuery } from 'src/hooks/use-media-query';
import styled from 'styled-components';

/**
 * `RowProps` type.
 */

type RowProps = AnimatedScrollBlocksSectionFragment['items'][0] & {
  bits: number[];
  hasScrollControl: boolean;
  positions: number[];
  scrollMotion: MotionValue<number>;
};

/**
 * Height constants.
 */

const minHeight = 73;
const maxHeight = 360;
const minHeightPercentage = (minHeight * 100) / 446;
const maxHeightPercentage = (maxHeight * 100) / 446;
const verticalGap = 16;

/**
 * `ScrollWrapper` styled component.
 */

const ScrollWrapper = styled.div`
  position: relative;

  &[data-has-scroll-control='true'] {
    height: var(--scroll-wrapper-height, 200vh);
  }
`;

/**
 * `FixedContent` styled component.
 */

const FixedContent = styled.div`
  display: grid;
  gap: 60px;

  [data-has-scroll-control='true'] & {
    display: flex;
    flex-direction: column;
    gap: ${verticalGap}px;
    margin-top: calc(var(--fixed-top-offset, 0) * -1);
    padding-top: var(--fixed-top-offset, 0);
    position: sticky;
    top: 0;
  }
`;

/**
 * `BlockCard` styled component.
 */

const BlockCard = styled.div`
  display: grid;
  gap: 16px;

  [data-has-scroll-control='true'] & {
    align-items: center;
    gap: 52px;
    grid-template-columns: 1fr 1fr;
  }
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled(motion.div)`
  border-radius: var(--border-radius);
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  width: 100%;

  [data-has-scroll-control='false'] & {
    margin: 0 var(--gutter-cards);
    width: calc(100% - (2 * var(--gutter-cards)));
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'subtitle2' })`
  ${textStyles.paragraph1}
  ${headingStyles}

  padding-top: 16px;

  [data-has-scroll-control='true'] & {
    inset: 50% 0 auto;
    position: absolute;
    transform: translateY(-50%);
  }

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }

  .lead {
    ${textStyles.subtitle2}
  }

  .title {
    ${textStyles.heading4}

    font-weight: 700;
  }

  ${media.min.md`
    padding: 0 32px;
  `}
`;

/**
 * `getPositions` function.
 *
 * First and last elements have a different only have two moments since the first starts open and the last remains closed.
 */

function getPositions(index: number, delay: number, length: number) {
  switch (index) {
    case 0:
      return [
        [0, delay],
        [1, 0]
      ];

    case length - 1:
      return [
        [1 - delay, 1],
        [0, 1]
      ];

    default:
      return [
        [(index - 1) * delay, index * delay, (index + 1) * delay],
        [0, 1, 0]
      ];
  }
}

/**
 * `Row` component.
 */

function Row(props: RowProps) {
  const { bits, content, hasScrollControl, id, media, positions, scrollMotion } = props;
  const willChange = useWillChange();
  const mapBits = useCallback((truly: number, falsy: number) => bits.map(bit => (bit === 1 ? truly : falsy)), [bits]);
  const opacity = useTransform(scrollMotion, positions, mapBits(1, 0.4), { ease: easeInOut });
  const paddingTopValue = useTransform(scrollMotion, positions, mapBits(maxHeightPercentage, minHeightPercentage));
  const height = useTransform(scrollMotion, positions, mapBits(maxHeight, minHeight));
  const textOpacity = useTransform(scrollMotion, positions, bits, { ease: easeInOut });
  const paddingTop = useMotionTemplate`${paddingTopValue}%`;
  const verticalPercentage = useTransform(scrollMotion, positions, mapBits(0, 20));
  const maskImage = useMotionTemplate`linear-gradient(0deg, transparent ${verticalPercentage}%, black calc(${verticalPercentage}% * 2), black calc((100% - ${verticalPercentage}%) * 0.85), transparent calc(100% - ${verticalPercentage}%))`;

  return (
    <BlockCard key={id}>
      <AssetWrapper style={hasScrollControl ? { opacity, paddingTop, willChange } : { opacity: 1, paddingTop: '100%' }}>
        <Media imageProps={{ fill: true }} media={media} />
      </AssetWrapper>

      <motion.div
        style={
          hasScrollControl
            ? {
                height,
                maskImage,
                opacity: textOpacity,
                position: 'relative',
                willChange
              }
            : { height: 'auto', maskImage: 'none', opacity: 1 }
        }
      >
        <RichTextClean element={Description}>{content}</RichTextClean>
      </motion.div>
    </BlockCard>
  );
}

/**
 * Export `AnimatedScrollBlocksSection` component.
 */

export function AnimatedScrollBlocksSection({ items, ...sectionProps }: AnimatedScrollBlocksSectionFragment) {
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({ target: scrollElementRef });
  const { contentHeight, itemsWithPlacements, wrapperStyle } = useMemo(() => {
    const delay = 1 / (items.length - 1);
    const contentHeight = (minHeight + verticalGap) * (items.length - 1) + maxHeight;
    const itemsWithPlacements = items.map((item, index) => {
      const [positions, bits] = getPositions(index, delay, items.length);

      return { ...item, bits, positions };
    });

    const wrapperStyle = {
      '--fixed-top-offset': `calc((100vh - ${contentHeight}px + var(--navbar-height) + var(--navbar-offset-top)) / 2)`,
      '--scroll-wrapper-height': `${items.length * 2 * maxHeight}px`
    } as CSSProperties;

    return { contentHeight, itemsWithPlacements, wrapperStyle };
  }, [items]);

  const hasScrollControl = !!useMediaQuery(`(min-width: ${breakpoints.md}px) and (min-height: ${contentHeight}px)`);

  return (
    <Section {...sectionProps} containerSize={'narrow'}>
      <ScrollWrapper data-has-scroll-control={hasScrollControl} ref={scrollElementRef} style={wrapperStyle}>
        <FixedContent>
          {itemsWithPlacements.map(item => (
            <Row {...item} hasScrollControl={hasScrollControl} key={item.id} scrollMotion={scrollYProgress} />
          ))}
        </FixedContent>
      </ScrollWrapper>
    </Section>
  );
}
