/**
 * Module dependencies.
 */

import { GlobalSettings } from 'src/api/entities/settings/global/types';
import BigNumber from 'bignumber.js';

/**
 * Export `AssetFormatProps` type.
 */

export type AssetFormatProps = {
  decimal: string;
  grouping: string;
};

/**
 * Export `AssetType` type.
 */

export type AssetType = 'commodity' | 'cryptocurrency' | 'equity' | 'fiat' | 'stablecoin' | 'utility_token';

/**
 * Export `AssetProps` type.
 */

export type AssetProps = {
  code: string;
  color: string;
  formatting: AssetFormatProps;
  image: string;
  name: string;
  symbol: string;
  type: AssetType;
};

/**
 * Export `isCryptoStrict` util.
 */

export const isCryptoStrict = ({ type }: Partial<AssetProps> = {}) => type?.toLowerCase() === 'cryptocurrency';

/**
 * Export `isCrypto` util.
 */

export const isCrypto = ({ type }: Partial<AssetProps> = {}) => {
  return !!type && ['cryptocurrency', 'stablecoin', 'utility_token'].includes(type.toLowerCase());
};

/**
 * Export `isEquity` util.
 */

export const isEquity = ({ type }: Partial<AssetProps> = {}) => type?.toLowerCase() === 'equity';

/**
 * Export `isFiat` util.
 */

export const isFiat = ({ type }: Partial<AssetProps> = {}) => type?.toLowerCase() === 'fiat';

/**
 * Export `PrecisionLevels` util.
 */

export type PrecisionLevels = GlobalSettings['format'];

/**
 * Export `getOm` util.
 */

export const getOm = (amount: BigNumber) => {
  if (amount.isZero() || amount.isNaN()) {
    return 0;
  }

  return Math.floor(Math.log10(amount.abs().toNumber()));
};

/**
 * `getPrecision` util.
 */

const getPrecision = (amount: BigNumber, precisionLevels: PrecisionLevels, asset?: AssetProps) => {
  const om = getOm(amount);

  if (isFiat(asset) && amount.isInteger()) {
    return 0;
  }

  const precisionLevelsType = precisionLevels?.[isFiat(asset) ? 'fiat' : 'crypto'];

  if (!precisionLevelsType) {
    return 8;
  }

  precisionLevelsType.sort((first, second) => second.om - first.om);

  return precisionLevelsType.find(precisionLevel => om >= precisionLevel.om)?.precision ?? 8;
};

/**
 * Export `formatAmount` util.
 */

export const formatAmount = (
  amount: string | number | BigNumber,
  precisionLevels: PrecisionLevels | undefined,
  asset?: AssetProps
) => {
  const parsedAmount = new BigNumber(amount);

  if (parsedAmount.isNaN()) {
    return 'NaN';
  }

  // @ts-expect-error - `getPrecision` already handle undefined precision levels.
  const precision = getPrecision(parsedAmount, precisionLevels, asset);

  if (!asset) {
    return parsedAmount.toFormat();
  }

  const {
    code,
    formatting: { decimal, grouping },
    symbol
  } = asset;

  const bignumberFormat = {
    decimalSeparator: decimal,
    groupSeparator: grouping,
    groupSize: 3
  };

  const formattedAmount = parsedAmount.abs().toFormat(precision, BigNumber.ROUND_DOWN, bignumberFormat);
  const signal = parsedAmount.isNegative() ? '-' : '';
  const affix = isFiat(asset) ? `${symbol}\u2009` : ` ${code}`;

  return isFiat(asset) ? `${signal}${affix}${formattedAmount}` : `${signal}${formattedAmount}${affix}`;
};
