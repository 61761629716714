/**
 * Module dependencies.
 */

import { Breakpoint } from 'src/styles/breakpoints';
import { CSSProperties, ComponentPropsWithRef, Ref, forwardRef, memo } from 'react';
import { Image, ImageProps } from 'src/components/core/image';
import { ImageFragment, Media as MediaProps } from 'src/api/entities/media/types';
import { Video } from 'src/components/core/video';
import { useDeviceSource } from 'src/hooks/use-device-source';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  imageProps?: Omit<ImageProps, 'src'>;
  media?: MediaProps;
  mobileBreakpoint?: Breakpoint;
  style?: CSSProperties;
  videoProps?: Omit<ComponentPropsWithRef<'video'>, 'src'>;
};

/**
 * `MediaImageProps` type.
 */

type MediaImageProps = Props['imageProps'] &
  Pick<Props, 'className' | 'mobileBreakpoint' | 'style'> & {
    media: ImageFragment;
  };

/**
 * `MediaImage` component.
 */

function MediaImage({ media, mobileBreakpoint = 'ms', style, ...props }: MediaImageProps) {
  const asset = useDeviceSource([media.desktop?.asset, media.mobile?.asset], mobileBreakpoint);

  if (!asset) {
    return null;
  }

  return <Image {...props} alt={asset.alt} src={asset.url} style={{ ...style, objectFit: asset.layout }} />;
}

/**
 * `MediaComponent` component.
 */

const MediaComponent = forwardRef<HTMLElement, Props>((props, ref) => {
  const { imageProps, media, mobileBreakpoint = 'ms', style, videoProps, ...rest } = props;

  switch (media?.type) {
    case 'image':
      return (
        <MediaImage
          {...imageProps}
          {...rest}
          media={media}
          mobileBreakpoint={mobileBreakpoint}
          style={{ backgroundColor: media.backgroundColor, ...imageProps?.style, ...style }}
        />
      );

    case 'video':
      return (
        <Video
          {...videoProps}
          {...rest}
          assets={{
            desktop: media.desktop?.asset,
            mobile: media.mobile?.asset
          }}
          assetsWebkit={{
            desktop: media.desktop?.assetWebKit,
            mobile: media.mobile?.assetWebKit
          }}
          loop={media.loop}
          mobileBreakpoint={mobileBreakpoint}
          ref={ref as Ref<HTMLVideoElement>}
          style={{ backgroundColor: media.backgroundColor, ...videoProps?.style, ...style }}
          thumbnails={{
            desktop: media.desktop?.thumbnail,
            mobile: media.mobile?.thumbnail
          }}
        />
      );

    default:
      return null;
  }
});

/**
 * `MediaComponent` display name.
 */

MediaComponent.displayName = 'MediaComponent';

/**
 * Export memoized `Media` component.
 */

export const Media = memo(MediaComponent);
