/**
 * Module dependencies.
 */

import { DoubleColumnStickySectionFragment } from 'src/api/entities/sections/double-column-sticky/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Section, SectionProps } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & DoubleColumnStickySectionFragment;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: start;
  display: grid;
  gap: 40px;

  ${media.min.sm`
    gap: 88px;
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `StickyColumn` styled component.
 */

const StickyColumn = styled.div`
  margin-top: 50%;
  position: sticky;
  top: calc(
    var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 2
  );
`;

/**
 * `AssetsColumn` styled component.
 */

const AssetsColumn = styled.div`
  display: grid;
  gap: 32px;
`;

/**
 * `TextContentWrapper` styled component.
 */

const TextContentWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-auto-rows: max-content;

  ${media.min.sm`
    gap: 32px;
  `}

  ${media.min.ms`
    gap: 40px;
  `}
`;

/**
 * `StyledText` styled component.
 */

const StyledText = styled(Text)`
  font-weight: 500;

  ${headingStyles}
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled.div`
  @property --bg-angle {
    syntax: '<angle>';
    initial-value: 45deg;
    inherits: false;
  }

  @keyframes rotate {
    to {
      --bg-angle: 225deg;
    }
  }

  --asset-border-radius: 24px;

  height: max-content;
  position: relative;

  video,
  img {
    border-radius: var(--asset-border-radius);
    display: block;
    position: relative;
  }

  ${media.max.sm`
    --asset-border-radius: 16px;

    margin: 0 var(--gutter-cards);

    &[data-border-animated='true'] {
      margin: 0 calc(4px + var(--gutter-cards));
    }
  `}

  &[data-border-animated='true']::before {
    animation: rotate 2s ease infinite;
    background: linear-gradient(
      var(--bg-angle),
      transparent 15%,
      var(--color-primary) 40%,
      var(--color-primary) 60%,
      transparent 85%
    );
    border-radius: calc(var(--asset-border-radius) + 2px);
    content: '';
    inset: -4px;
    position: absolute;
  }
`;

/**
 * `AssetContent` component.
 */

const AssetContent = styled(Text).attrs({ variant: 'subtitle2' })`
  ${headingStyles}

  inset: auto 48px 48px;
  position: absolute;
`;

/**
 * `TextContent` component.
 */

const TextContent = ({ description, subtitle, title }: Props['stickyContent']) => (
  <TextContentWrapper>
    {!isEmpty(title) && (
      <motion.div {...blurFadeInTopAnimation()}>
        <RawHtml element={StyledText} elementProps={{ as: 'div', variant: 'heading3' }}>
          {title}
        </RawHtml>
      </motion.div>
    )}

    {!isEmpty(subtitle) && (
      <motion.div {...blurFadeInTopAnimation()}>
        <RawHtml element={StyledText} elementProps={{ as: 'div', variant: 'subtitle1' }}>
          {subtitle}
        </RawHtml>
      </motion.div>
    )}

    {!isEmpty(description) && (
      <motion.div {...blurFadeInTopAnimation()}>
        <RawHtml element={StyledText} elementProps={{ as: 'div', variant: 'subtitle2' }}>
          {description}
        </RawHtml>
      </motion.div>
    )}
  </TextContentWrapper>
);

/**
 * Export `DoubleColumnStickySection` component.
 */

export const DoubleColumnStickySection = ({
  assets,
  assetsAnimatedBorder,
  assetsContent,
  stickyContent,
  ...rest
}: Props) => {
  const isDesktop = useBreakpoint('sm');

  return (
    <Section {...rest}>
      <Grid>
        {isDesktop ? (
          <>
            <StickyColumn>
              <TextContent {...stickyContent} />
            </StickyColumn>

            <AssetsColumn>
              {assets.map((asset, index) => (
                <AssetWrapper data-border-animated={assetsAnimatedBorder?.[index]} key={index}>
                  <Media media={asset} />

                  {assetsContent?.[index] && <RawHtml element={AssetContent}>{assetsContent[index]}</RawHtml>}
                </AssetWrapper>
              ))}
            </AssetsColumn>
          </>
        ) : (
          <>
            <AssetWrapper data-border-animated={assetsAnimatedBorder?.[0]}>
              <Media media={assets[0]} />

              {assetsContent?.[0] && <RawHtml element={AssetContent}>{assetsContent[0]}</RawHtml>}
            </AssetWrapper>

            <TextContent {...stickyContent} />

            <AssetWrapper data-border-animated={assetsAnimatedBorder?.[1]}>
              <Media media={assets[1]} />

              {assetsContent?.[1] && <RawHtml element={AssetContent}>{assetsContent[1]}</RawHtml>}
            </AssetWrapper>
          </>
        )}
      </Grid>
    </Section>
  );
};
