/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Svg } from 'src/components/core/svg';
import { textStyles } from 'src/styles/typography';
import checkmarkIcon from 'src/assets/svgs/20/checkmark.svg';
import checkmarkIndeterminateIcon from 'src/assets/svgs/20/checkmark-indeterminate.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `CheckboxProps` type.
 */

export type CheckboxProps = Omit<ComponentPropsWithoutRef<'input'>, 'type'> & Omit<FormGroupProps, 'size' | 'variant'>;

/**
 * `StyledFormGroup` styled component.
 */

const StyledFormGroup = styled(FormGroup)`
  --checkbox-checkmark-color: var(--color-white);

  position: relative;

  &[data-theme='dark'],
  [data-theme='dark'] & {
    --checkbox-checkmark-color: var(--color-neutral105);
  }

  &:not([data-empty='true']) {
    grid-template-areas: '. input label .' '. . helptext .';
    grid-template-columns: var(--labels-gap) 36px 1fr var(--labels-gap);
  }

  label {
    ${textStyles.paragraph3}

    font-weight: 500;
    line-height: 1;
  }

  label,
  input {
    cursor: pointer;
  }
`;

/**
 * `FormControl` styled component.
 */

const FormControl = styled.input<Pick<CheckboxProps, 'error'>>`
  --checkbox-background-color: var(--color-primary);
  --checkbox-border-color: var(--color-neutral10);
  --checkbox-error-color: var(--color-critical60);
  --checkbox-disabled-color: var(--color-neutral05);

  [data-theme='dark'] & {
    --checkbox-border-color: var(--color-neutral70);
    --checkbox-error-color: var(--color-critical40);
    --checkbox-disabled-color: var(--color-neutral80);
  }

  [data-error='true'] & {
    --checkbox-background-color: var(--checkbox-error-color);
    --checkbox-border-color: var(--checkbox-error-color);
  }

  [data-disabled='true'] & {
    --checkbox-background-color: var(--checkbox-disabled-color);
    --checkbox-border-color: var(--checkbox-disabled-color);
  }

  appearance: none;
  height: 20px;
  width: 100%;

  &::before {
    border: 2px solid var(--checkbox-border-color);
    border-radius: 4px;
    content: '';
    height: 20px;
    left: 0;
    position: absolute;
    transition: var(--transition-default);
    transition-property: background-color, border-width, border-color;
    width: 20px;
  }

  &:checked::before,
  &:indeterminate::before {
    background-color: var(--checkbox-background-color);
    border-color: var(--checkbox-background-color);
    border-width: 0;
  }
`;

/**
 * Icon common styles.
 */

const iconStyles = `
  color: var(--checkbox-checkmark-color);
  height: 20px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-property: opacity, visibility;
  transition: var(--transition-default);
  visibility: hidden;
  width: 20px;
`;

/**
 * `CheckmarkIcon` styled component.
 */

const CheckmarkIcon = styled(Svg).attrs({ icon: checkmarkIcon })`
  ${iconStyles}

  ${FormControl}:checked:not(:indeterminate) ~ & {
    opacity: 1;
    visibility: visible;
  }
`;

/**
 * `CheckmarkIndeterminateIcon` styled component.
 */

const CheckmarkIndeterminateIcon = styled(Svg).attrs({ icon: checkmarkIndeterminateIcon })`
  ${iconStyles}

  ${FormControl}:indeterminate ~ & {
    opacity: 1;
    visibility: visible;
  }
`;

/**
 * Export `Checkbox` component.
 */

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { className, disabled, error, helpText, id, label, labelHasHtml, value, ...rest } = props;

  return (
    <StyledFormGroup
      className={className}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      label={label}
      labelHasHtml={labelHasHtml}
      value={value}
    >
      <div style={{ lineHeight: 0, position: 'relative', top: -3 }}>
        <FormControl {...omit(rest, ['as'])} disabled={disabled} error={error} id={id} ref={ref} type={'checkbox'} />

        <CheckmarkIndeterminateIcon />

        <CheckmarkIcon />
      </div>
    </StyledFormGroup>
  );
});

/**
 * `Checkbox` display name.
 */

Checkbox.displayName = 'Checkbox';
