/**
 * Module dependencies.
 */

import { CryptoAsset } from 'src/api/entities/crypto-assets/types';
import { Option } from 'src/components/core/forms/select';
import { Text } from 'src/components/core/text';
import { useFormatCurrency } from 'src/hooks/format/use-format-currency';
import styled from 'styled-components';

/**
 * Export `Row` styled component.
 */

export const Row = styled.div`
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr max-content;
  position: relative;

  > * {
    cursor: pointer;
  }
`;

/**
 * `AssetValueWrapper` styled component.
 */

const AssetValueWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 1px 12px;
  grid-template-areas: 'logo code';
  grid-template-columns: 24px 1fr;
  justify-items: start;
  text-align: left;
  width: 100%;
`;

/**
 * `AssetOptionWrapper` styled component.
 */

const AssetOptionWrapper = styled(AssetValueWrapper)`
  grid-template-areas:
    'logo name value'
    'logo code value';
  grid-template-columns: max-content 1fr max-content;

  [data-muted='true'] {
    color: var(--color-neutral40);
  }

  [data-headlessui-state~='selected'] & [data-muted='true'] {
    color: var(--color-white);
  }
`;

/**
 * `AssetLogo` styled component.
 */

const AssetLogo = styled.span`
  background-position: center;
  background-size: cover;
  grid-area: logo;
  height: 24px;
  width: 24px;
`;

/**
 * Export `AssetOption` component.
 */

export const AssetOption = ({ label: name, props, value: code }: Option<Pick<CryptoAsset, 'image' | 'value'>>) => {
  const { formatCurrency } = useFormatCurrency();

  return (
    <AssetOptionWrapper>
      {props?.image && <AssetLogo style={{ backgroundImage: `url(${props.image})` }} />}

      <Text style={{ gridArea: 'name' }} variant={'paragraph3'}>
        {name}
      </Text>

      <Text data-muted fontWeight={400} style={{ gridArea: 'code' }} variant={'small'}>
        {code}
      </Text>

      {!!props?.value && (
        <Text style={{ gridArea: 'value' }} variant={'paragraph3'}>
          {formatCurrency(props.value)}
        </Text>
      )}
    </AssetOptionWrapper>
  );
};

/**
 * Export `AssetValue` component.
 */

export const AssetValue = ({ props, value: code }: Option<Pick<CryptoAsset, 'image'>>) => {
  return (
    <AssetValueWrapper>
      {props?.image && <AssetLogo style={{ backgroundImage: `url(${props.image})` }} />}

      <Text style={{ gridArea: 'code' }} variant={'paragraph3'}>
        {code}
      </Text>
    </AssetValueWrapper>
  );
};
