/**
 * Module dependencies.
 */

import { Media } from 'src/components/media';
import { ProcessCardsSectionFragment } from 'src/api/entities/sections/process-cards/types';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { prop } from 'styled-tools';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = ProcessCardsSectionFragment;

/**
 * Export `CardWrapper` styled component.
 */

export const CardWrapper = styled(motion.div).withConfig({
  shouldForwardProp: prop => !['mobileAspectRatio'].includes(prop)
})<{ mobileAspectRatio?: number | string }>`
  --card-background-color: var(--color-neutral0);
  --card-color: var(--color-neutral70);

  &[data-theme='dark'],
  [data-theme='dark'] & {
    --card-background-color: var(--color-neutral95);
    --card-color: var(--color-neutral0);
  }

  align-content: end;
  align-items: center;
  background-color: var(--card-background-color);
  border-radius: var(--border-radius);
  color: var(--card-color);
  display: grid;
  height: 100%;
  overflow: hidden;
  padding: 32px;
  position: relative;

  ${media.max.ms`
    aspect-ratio: ${prop('mobileAspectRatio', '2 / 3')};
    margin: 0 auto;
    max-width: 410px;
    padding: 24px;
    width: 100%;
  `}
`;

/**
 * Export `CardTextWrapper` styled component.
 */

export const CardTextWrapper = styled.div`
  align-content: start;
  display: grid;
  gap: 12px;
  position: relative;
  z-index: 1;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'div', fontWeight: 700, variant: 'subtitle1' })`
  color: var(--color-primary);
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Text).attrs({ as: 'div', variant: 'subtitle2' })`
  ${headingStyles}
`;

/**
 * `Body` styled component.
 */

const Body = styled(Text).attrs({ as: 'div', variant: 'paragraph1' })`
  ${headingStyles}
`;

/**
 * Export `CardText` component.
 */

export const CardText = ({ index, item }: { index: number; item: Props['items'][number] }) => (
  <>
    <Text as={'div'} fontWeight={700} variant={'heading4'}>{`${index + 1}.`}</Text>

    {!isEmpty(item.title) && <Title>{item.title}</Title>}

    {!isEmpty(item.subtitle) && <RawHtml element={Subtitle}>{item.subtitle}</RawHtml>}

    {!isEmpty(item.body) && <RawHtml element={Body}>{item.body}</RawHtml>}
  </>
);

/**
 * Export `CardAsset` component.
 */

export const CardAsset = (item: Props['items'][number]) => <Media imageProps={{ fill: true }} media={item.media} />;
