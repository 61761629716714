/* eslint-disable id-match */

/**
 * Module dependencies.
 */

import { DcaCalculatorSection } from 'src/api/entities/sections/dca/types';
import { Link } from 'src/components/core/links';
import { RawHtml } from 'src/components/core/raw-html';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { Totals } from './index';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { stripTags } from 'src/core/utils/html-client';
import { useDca } from 'src/context/dca';
import { useFormat } from 'src/hooks/format/use-format';
import { useFormatAsset } from 'src/hooks/format/use-format-asset';
import { useFormatCurrency } from 'src/hooks/format/use-format-currency';
import { useSettings } from 'src/context/settings';
import styled from 'styled-components';
import template from 'lodash/template';

/**
 * `ParseProps` type.
 */

type ParseProps = {
  repeatOptions: DcaCalculatorSection['formFields']['repeat']['options'];
  totals: Totals;
};

/**
 * `Props` type.
 */

type Props = DcaCalculatorSection['results'] & ParseProps;

/**
 * `resultsPlaceholder` constant.
 */

const resultsPlaceholder = '<span class="spinner"><span>.</span><span>.</span><span>.</span></span>';

/**
 * `templateOptions` constant.
 */

const templateOptions = { interpolate: /{{(\w*)}}/g };

/**
 * `useParsedDescription` hook.
 */

function useParsedDescription({ description, repeatOptions, totals }: ParseProps & { description?: string }) {
  const { asset, dateEnd, dateStart, initialAmount, repeatDays } = useDca();
  const { totalPurchaseAmount, totalValue, totalValueAsset } = totals;
  const { formatMonthLong } = useFormat();
  const { formatAsset } = useFormatAsset();
  const { formatCurrency } = useFormatCurrency();

  if (!description) {
    return;
  }

  const descriptionTemplate = template(description, templateOptions);

  return descriptionTemplate({
    amount: initialAmount !== '' ? formatCurrency(initialAmount) : resultsPlaceholder,
    asset: asset !== undefined ? asset : resultsPlaceholder,
    end: dateEnd !== undefined ? formatMonthLong(dateEnd) : resultsPlaceholder,
    repeat: repeatOptions?.find?.(option => option.days === repeatDays)?.label?.toLowerCase?.() ?? resultsPlaceholder,
    return_asset: totalValueAsset !== undefined ? formatAsset(totalValueAsset) : resultsPlaceholder,
    return_currency: totalValue !== undefined ? formatCurrency(totalValue) : resultsPlaceholder,
    start: dateStart !== undefined ? formatMonthLong(dateStart) : resultsPlaceholder,
    total: totalPurchaseAmount !== undefined ? formatCurrency(totalPurchaseAmount) : resultsPlaceholder
  });
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: min(100vw, var(--container-narrow-max-width));
  width: 100%;

  ${media.min.md`
    padding: 0 var(--gutter);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'div', variant: 'heading1' })`
  ${headingStyles}

  margin-bottom: 32px;
  margin-top: 104px;

  ${media.min.md`
    margin-bottom: 88px;
    margin-top: 192px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'div', variant: 'heading4' })`
  ${headingStyles}

  margin-bottom: 24px;

  ${media.min.md`
    margin-bottom: 48px;
  `}

  @keyframes spinner {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  .spinner > span {
    animation: spinner 3s infinite;
  }

  .spinner > span:nth-child(2) {
    animation-delay: 1s;
  }

  .spinner > span:nth-child(3) {
    animation-delay: 2s;
  }
`;

/**
 * `Share` styled component.
 */

const Share = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'paragraph1' })`
  margin-bottom: 16px;
`;

/**
 * `ShareIcons` styled component.
 */

const ShareIcons = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

/**
 * Export `DcaCalculatorResults` component.
 */

export const DcaCalculatorResults = ({ share, shareSubject, title, ...rest }: Props) => {
  const { globalSettings } = useSettings();
  const parsedDescription = useParsedDescription(rest);

  return (
    parsedDescription && (
      <Wrapper>
        <RawHtml element={Title}>{title}</RawHtml>

        <RawHtml element={Description}>{parsedDescription}</RawHtml>

        <Share>{share}</Share>

        <ShareIcons>
          {globalSettings?.socialNetworks?.map?.(
            ({ icon, label, shareUrl, showInShareable }) =>
              showInShareable &&
              shareUrl &&
              icon && (
                <Link
                  href={template(
                    shareUrl,
                    templateOptions
                  )({
                    body: (() => {
                      let body = stripTags(parsedDescription);

                      if (!shareUrl.includes('{{url}}')) {
                        body += `%0A%0A${encodeURIComponent(window.location.href)}`;
                      }

                      return body;
                    })(),
                    subject: encodeURIComponent(shareSubject),
                    url: encodeURIComponent(window.location.href)
                  })}
                  key={label}
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                >
                  <Svg color={'var(--color-primary)'} icon={icon} size={'32px'} />
                </Link>
              )
          )}
        </ShareIcons>
      </Wrapper>
    )
  );
};
