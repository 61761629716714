/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, MouseEventHandler, useCallback, useRef, useState } from 'react';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { motion, useIsomorphicLayoutEffect } from 'framer-motion';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  &[data-clickable='true'] {
    cursor: pointer;

    &:focus,
    &:focus-within,
    &:hover {
      a[data-main-cta='true'],
      button[data-main-cta='true'] {
        background-color: var(--button-hover-background-color);
        color: var(--button-hover-text-color);
      }
    }
  }
`;

/**
 * Export `AnimatedWrapper` component.
 */

export const AnimatedWrapper = ({
  children,
  colIndex,
  ...rest
}: ComponentPropsWithoutRef<'div'> & { colIndex?: number }) => {
  const [mainCta, setMainCta] = useState<HTMLButtonElement | HTMLAnchorElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      const target = event.target as HTMLElement;

      if (target.onclick || target.tagName === 'A' || target.tagName === 'BUTTON') {
        return;
      }

      mainCta?.click();
    },
    [mainCta]
  );

  useIsomorphicLayoutEffect(() => {
    const mainCta = ref.current?.querySelector("[data-card-button='true']");

    mainCta?.setAttribute('data-main-cta', 'true');
    setMainCta((mainCta as HTMLButtonElement | HTMLAnchorElement) ?? null);
  }, []);

  return (
    <Wrapper {...rest} data-clickable={!!mainCta} onClick={handleClick} ref={ref}>
      <motion.div {...blurFadeInTopAnimation(colIndex ? colIndex * 0.15 : undefined, 30)} style={{ height: '100%' }}>
        {children}
      </motion.div>
    </Wrapper>
  );
};
