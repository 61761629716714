/**
 * Module dependencies.
 */

import { AssetOption, AssetValue } from './shared';
import { AssetProps } from 'src/core/utils/assets';
import { Select } from 'src/components/core/forms/select';
import { useDca } from 'src/context/dca';
import { useMemo } from 'react';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `AsssetFieldProps` type.
 */

export type AsssetFieldProps = {
  helpText?: string;
  label: string;
  options: Pick<AssetProps, 'code' | 'image' | 'name'>[];
};

/**
 * Export `DcaFormAssetField` component.
 */

export const DcaFormAssetField = (props: AsssetFieldProps) => {
  const { t } = useTranslate();
  const { asset, updateDcaState } = useDca();
  const options = useMemo(
    () =>
      props.options.map(({ code, name, ...props }) => {
        new Image().src = props.image;

        return {
          label: name,
          props,
          value: code
        };
      }),
    [props.options]
  );

  return (
    <Select
      components={{
        Option: AssetOption,
        Value: AssetValue
      }}
      filterPlaceholder={t('actions.search')}
      helpText={props.helpText}
      id={'asset'}
      isFilterable
      label={props.label}
      onChange={({ value }) => updateDcaState({ asset: value as string })}
      options={options}
      style={{ position: 'relative' }}
      value={options.find(({ value }) => value === asset)}
      virtualized
    />
  );
};
