/**
 * Export `getFileType` util.
 */

export function getFileType(filetype: string | undefined) {
  if (!filetype || typeof filetype !== 'string') {
    return undefined;
  }

  if (filetype?.startsWith?.('image')) {
    return 'image';
  }

  if (filetype?.startsWith?.('video')) {
    return 'video';
  }

  return undefined;
}
