/**
 * Module dependencies
 */

import { CardsRowsAssetTextSectionFragment } from 'src/api/entities/sections/cards-rows-asset-text/types';
import { Image } from 'src/components/core/image';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { fadeInLeftAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import styled from 'styled-components';

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--grid-row-gap);
  margin: 0 var(--gutter-cards);
`;

/**
 * `Card` styled component.
 */

const Card = styled(motion.div)`
  --card-background-color: var(--color-neutral0);

  [data-theme='dark'] & {
    --card-background-color: var(--color-neutral95);
  }

  align-items: center;
  background-color: var(--card-background-color);
  border-radius: var(--border-radius);
  color: var(--color-text);
  display: grid;
  grid-gap: 24px;
  grid-template-areas: 'asset' 'content';
  overflow: hidden;
  padding: 24px;
  position: relative;

  ${media.min.ms`
    align-items: center;
    grid-gap: var(--grid-row-gap);
    grid-template-areas: 'asset . content';
    grid-template-columns: 230px max(20%, 80px) 1fr;
    justify-content: space-between;
    padding: 24px 32px;
  `}
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled.div`
  grid-area: asset;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${media.max.ms`
    height: 35px;
    margin: 10px 0;

    img, video {
      object-position: left;
    }
  `}

  ${media.min.ms`
    aspect-ratio: 230 / 132;
    height: auto;
    max-width: 230px;

    && > div {
      inset: 0 24px;
      width: unset;
    }
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled(Text).attrs({ variant: 'subtitle1' })`
  align-items: center;
  grid-area: content;
`;

/**
 * Export `CardsRowsAssetTextSection` component.
 */

export function CardsRowsAssetTextSection({ items, ...sectionProps }: CardsRowsAssetTextSectionFragment) {
  return (
    <Section {...sectionProps}>
      <Grid>
        {items.map(({ asset, content, id }) => (
          <Card key={id} {...fadeInLeftAnimation()}>
            <AssetWrapper>
              {asset && <Image alt={asset.alt} fill src={asset.url} style={{ objectFit: 'contain' }} />}
            </AssetWrapper>

            <RawHtml element={Content}>{content}</RawHtml>
          </Card>
        ))}
      </Grid>
    </Section>
  );
}
