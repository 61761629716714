/**
 * Module dependencies.
 */

import { SectionHeading, SectionWrapper } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * `ListSectionWrapper` styled component.
 */

export const ListSectionWrapper = styled(SectionWrapper)`
  grid-gap: 0;
  overflow: hidden;
  position: relative;

  &:not(:first-child) {
    padding-top: 0;
  }
`;

/**
 * Export `List` styled component.
 */

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 8px;

  li > div:first-child {
    --font-size: 20px;
    --line-height: 26px;

    ${headingStyles}

    align-items: start;
    display: grid;
    font-size: var(--font-size);
    font-weight: 400;
    gap: 8px;
    grid-template-areas: '. .' '. cta';
    grid-template-columns: var(--font-size) 1fr;
    line-height: var(--line-height);

    > #svg-arrow:first-child {
      position: relative;
      top: calc((var(--line-height) - var(--font-size)) / 2);
    }

    > a {
      grid-area: cta;
      margin: 20px 0 24px;
    }
  }

  ${media.min.ms`
    gap: 32px;
    padding: 16px 0;

    li {
      --font-size: 24px;
      --line-height: 32px;
    }
  `}
`;

/**
 * `StyledSectionHeading` styled component.
 */

export const StyledSectionHeading = styled(SectionHeading)`
  padding-bottom: 40px;

  ${media.min.sm`
    padding-bottom: 88px;
  `}

  &[data-has-marquee='true'] {
    margin-bottom: -64px;
    padding-bottom: 0;

    ${media.min.ms`
      margin-bottom: -128px;
    `}
  }
`;

/**
 * `Title` styled component.
 */

export const Title = styled(Text).attrs({ as: 'h3', fontWeight: 400, variant: 'heading2' })`
  ${headingStyles}
`;

/**
 * Export `Subtitle` styled component.
 */

export const Subtitle = styled(Text).attrs({ as: 'div', fontWeight: 700, variant: 'heading3' })`
  ${headingStyles}
`;

/**
 * Export `Description` styled component.
 */

export const Description = styled(Text).attrs({ as: 'p', fontWeight: 400, variant: 'paragraph1' })`
  ${headingStyles}
`;
