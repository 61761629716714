/**
 * Module dependencies.
 */

import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { formatMonthSlug } from 'src/core/utils/format';
import { useIsClient } from 'src/context/client';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';

/**
 * `State` type.
 */

type State = {
  asset: string;
  currency: string;
  dateEnd: Date;
  dateStart: Date;
  initialAmount: number | '';
  repeatDays: number;
};

/**
 * `DcaContext` context.
 */

const DcaContext = createContext<
  State & {
    changedParams: string;
    params: string;
    updateDcaState: (newState: Partial<State>) => void;
  }
>(null!);

/**
 * Export `useDca` hook.
 */

export const useDca = () => useContext(DcaContext);

/**
 * `useUrlSearchParams` hook.
 */

function useUrlSearchParams() {
  return useMemo(() => {
    const searchParams = new URL(window.location.href).searchParams;

    const parseStringParam = (key: string) => {
      const value = searchParams.get(key);

      if (typeof value === 'string') {
        return value;
      }
    };

    const parseNumberParam = (key: string) => {
      const value = Number(searchParams.get(key));

      if (value && !isNaN(value)) {
        return value;
      }
    };

    const parseDateParam = (key: string) => {
      const value = searchParams.get(key);
      const date = value ? new Date(value) : undefined;

      if (date && !isNaN(date.getTime())) {
        return date;
      }
    };

    return {
      dateEnd: parseDateParam('dateEnd'),
      dateStart: parseDateParam('dateStart'),
      fsym: parseStringParam('fsym'),
      initial: parseNumberParam('initial'),
      repeat: parseNumberParam('repeat'),
      tsym: parseStringParam('tsym')
    };
  }, []);
}

/**
 * `getParamsObject` util.
 */

function getParamsObject(state: State) {
  return {
    dateEnd: formatMonthSlug(state.dateEnd),
    dateStart: formatMonthSlug(state.dateStart),
    fsym: state.asset,
    initial: String(state.initialAmount),
    repeat: String(state.repeatDays),
    tsym: state.currency
  };
}

/**
 * `ParamsObject` type.
 */

type ParamsObject = ReturnType<typeof getParamsObject>;

/**
 * `ProviderContent` provider.
 */

const ProviderContent = ({ children, ...defaults }: PropsWithChildren<State>) => {
  const initialUrlSearchParams = useUrlSearchParams();
  const [state, setState] = useState<State>({
    asset: initialUrlSearchParams.fsym ?? defaults.asset,
    currency: initialUrlSearchParams.tsym ?? defaults.currency,
    dateEnd: initialUrlSearchParams.dateEnd ?? new Date(defaults.dateEnd),
    dateStart: initialUrlSearchParams.dateStart ?? new Date(defaults.dateStart),
    initialAmount: initialUrlSearchParams.initial ?? defaults.initialAmount,
    repeatDays: initialUrlSearchParams.repeat ?? defaults.repeatDays
  });

  const defaultParams = useMemo(() => getParamsObject(defaults), [defaults]);
  const [changedParams, params] = useMemo(() => {
    const paramsObject = getParamsObject(state);
    const params = new URLSearchParams(
      pickBy(paramsObject, (value, key) => defaultParams[key as keyof ParamsObject] !== value)
    );

    if (params.size) {
      window.history.replaceState({}, '', `?${params.toString()}`);
    }

    return [params.toString(), new URLSearchParams(omit(paramsObject, 'initial')).toString()];
  }, [defaultParams, state]);

  const updateDcaState = useCallback((newState: Partial<State>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  }, []);

  return (
    <DcaContext.Provider value={{ ...state, changedParams, params, updateDcaState }}>{children}</DcaContext.Provider>
  );
};

/**
 * Export `DcaProvider` provider.
 */

export const DcaProvider = (props: PropsWithChildren<State>) => {
  const isClient = useIsClient();

  if (isClient) {
    return <ProviderContent {...props} />;
  }
};
