/**
 * Export `formatDate` util.
 */

export function formatDate(date: Date, options: Intl.DateTimeFormatOptions & { locale?: string }): string {
  const { locale, ...rest } = options;

  return new Intl.DateTimeFormat(locale || process.env.DEFAULT_LANGUAGE, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    ...rest
  }).format(date);
}

/**
 * Export `getDayDiff` util.
 */

export function getDayDiff(date1: Date, date2: Date) {
  const diff = Math.abs(date1.getTime() - date2.getTime());

  return Math.ceil(diff / (1000 * 60 * 60 * 24));
}

/**
 * Export `roundDate` util.
 *
 * This sets the Date for the first day of the month for that month and year.
 */

export function roundDate(date: Date) {
  return new Date(`${date.toISOString().slice(0, 8)}01`);
}
