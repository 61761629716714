/**
 * Module dependencies.
 */

import { DesktopProcessCards } from './desktop';
import { MobileProcessCards } from './mobile';
import { Props } from './shared';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `BottomLead` styled component.
 */

const BottomLead = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'heading4' })`
  ${headingStyles}

  margin-top: 56px;
  text-align: center;

  ${media.min.ms`
    margin-top: 120px;
  `}
`;

/**
 * `ProcessCardsSection` component.
 */

export const ProcessCardsSection = ({ bottomLead, items, ...sectionProps }: Props) => {
  const isDesktop = useBreakpoint('ms');
  const Cards = isDesktop ? DesktopProcessCards : MobileProcessCards;

  return (
    <Section {...sectionProps}>
      <Cards items={items} />

      {!isEmpty(bottomLead) && <RawHtml element={BottomLead}>{bottomLead}</RawHtml>}
    </Section>
  );
};
