/**
 * Export `LESSON_PROGRESS_KEY` constant.
 */

export const LESSON_PROGRESS_KEY = 'lesson-progress';

/**
 * Export `LessonProgress` type.
 */

export type LessonProgress = {
  [key: string]: {
    [key: string]: [number, number];
  };
};
