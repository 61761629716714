/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import { Form } from 'src/components/forms';
import { HeroFormSectionFragment } from 'src/api/entities/sections/hero-form/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Constants.
 */

const delay = 0.1;

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & HeroFormSectionFragment;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  --padding-top: calc(
    (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3) +
      32px
  );

  display: grid;

  ${media.max.md`
    align-items: end;
    min-height: 100vh;
    min-height: 100svh;
    position: relative;
    grid-template-rows: calc(200vh / 3) max-content;
    gap: 64px;
    padding-bottom: 48px;
  `}

  ${media.min.md`
    --columns-ratio: 5 / 9;
    --columns-gap: 32px;

    grid-template-columns: 1fr 1fr;
    width: 100vw;
  `}

  ${media.min.lg`
    --columns-ratio: 4 / 9;
    --columns-gap: 64px;
  `}

  ${media.min.xl`
    --columns-ratio: 2 / 5;

    &:not([data-size='narrow']) {
      --columns-gap: 168px;
    }
  `}
`;

/**
 * `StickyWrapper` styled component.
 */

const StickyWrapper = styled.div`
  padding-top: var(--padding-top);

  ${media.min.md`
    height: 100vh;
    position: sticky;
    top: 0;
    width: 100vw;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageOverlay = styled.div`
  --hero-gradient-base-color: var(--color-white);

  background: linear-gradient(
    180deg,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 100%) 70.26%,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 30%) 86%,
    var(--hero-gradient-base-color) 100%
  );
  inset: 0;
  position: absolute;

  [data-theme='dark'] & {
    --hero-gradient-base-color: var(--color-neutral105);
  }
`;

/**
 * `Headings` styled component.
 */

const Headings = styled.div`
  color: var(--color-text);
  display: grid;
  gap: 16px;

  ${media.min.md`
    max-width: calc(100% * (1 - var(--columns-ratio)) - var(--columns-gap));

    [data-layout='vertical_left'] & {
      left: 50%;
      position: relative;
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'h1', variant: 'heading2' })`
  font-weight: 700;

  ${headingStyles}
`;

/**
 * `Pretitle` styled component.
 */

const Pretitle = styled(Text).attrs({ as: 'h3', variant: 'subtitle1' })`
  font-weight: 400;
  margin-bottom: 8px;

  ${headingStyles}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'h2', variant: 'paragraph1' })`
  font-weight: 500;

  ${headingStyles}
`;

/**
 * `FormTitle` styled component.
 */

const FormTitle = styled(Text).attrs({ as: 'h4', variant: 'paragraph1' })`
  font-weight: 700;
  padding: 24px 24px 16px;

  ${headingStyles}
`;

/**
 * `Content` styled component.
 */

const Content = styled(Container)`
  position: relative;

  ${media.min.md`
    --total-container-width: min(100vw, var(--container-width));
    --form-width: calc(var(--total-container-width) * var(--columns-ratio));
    --headings-width: calc(var(--total-container-width) * (1 - var(--columns-ratio)));

    [data-layout='vertical_left'] & {
      /* stylelint-disable-next-line length-zero-no-unit */
      --headings-width: 0px;
    }

    left: calc(-50vw - min(100vw, var(--total-container-width)) / 2 + var(--headings-width));
    padding-bottom: 128px;
    padding-left: 0;
    padding-right: var(--gutter);
    padding-top: var(--padding-top);
    width: var(--form-width);
  `}
`;

/**
 * `FormCard` styled component.
 */

const FormCard = styled.div`
  border-radius: 16px;
  color: var(--color-text);

  &[data-background='true'] {
    background: var(--color-white);

    &[data-theme='dark'] {
      background: var(--color-neutral95);
    }
  }

  ${media.max.md`
    margin: 0 var(--gutter-cards);
  `}

  ${media.min.md`
    [data-layout='vertical_left'] & {
      margin-left: var(--gutter-cards);
    }

    [data-layout='vertical_right'] & {
      margin-right: var(--gutter-cards);
    }
  `}
`;

/**
 * `StyledForm` styled component.
 */

const StyledForm = styled(Form)`
  padding: 16px 24px 32px;
`;

/**
 * Export `HeroFormSection` component.
 */

export const HeroFormSection = (props: Props) => {
  const {
    containerSize,
    description,
    form,
    formTheme,
    formTitle,
    hasFormBackground,
    id,
    layout,
    media,
    pretitle,
    theme,
    title,
    ...rest
  } = props;

  const { delays, has } = useMemo(() => {
    const order = ['pretitle', 'title', 'description'] as (keyof typeof has)[];
    const has = {
      description: !isEmpty(description),
      formTitle: !isEmpty(formTitle),
      pretitle: !isEmpty(pretitle),
      title: !isEmpty(title)
    };

    const delays = order.reduce(
      (acc, key) => {
        const totalDelay = Object.values(acc).filter(Boolean).length * delay;

        if (has[key]) {
          acc[key] = totalDelay + delay;
        }

        return acc;
      },
      {} as Record<keyof typeof has, number>
    );

    return { delays, has };
  }, [description, formTitle, pretitle, title]);

  return (
    <Wrapper {...rest} data-layout={layout} data-size={containerSize} id={`${id}-wrapper`}>
      <StickyWrapper data-theme={theme}>
        <Media imageProps={{ fill: true, priority: true }} media={media} />

        <ImageOverlay />

        <Container size={containerSize}>
          <Headings>
            {has.pretitle && (
              <motion.div {...blurFadeInTopAnimation(delays?.pretitle)}>
                <RawHtml element={Pretitle}>{pretitle}</RawHtml>
              </motion.div>
            )}

            {has.title && (
              <motion.div {...blurFadeInTopAnimation(delays?.title)}>
                <RawHtml element={Title}>{title}</RawHtml>
              </motion.div>
            )}

            {has.description && (
              <motion.div {...blurFadeInTopAnimation(delays?.description)}>
                <RawHtml element={Description}>{description}</RawHtml>
              </motion.div>
            )}
          </Headings>
        </Container>
      </StickyWrapper>

      <Content size={containerSize}>
        <FormCard data-background={hasFormBackground} data-theme={formTheme}>
          {has.formTitle && (
            <motion.div {...blurFadeInTopAnimation()}>
              <RawHtml element={FormTitle}>{formTitle}</RawHtml>
            </motion.div>
          )}

          <StyledForm {...form} id={id} />
        </FormCard>
      </Content>
    </Wrapper>
  );
};
