/**
 * Module dependencies.
 */

import { PropsWithChildren, forwardRef } from 'react';
import { RawHtml } from 'src/components/core/raw-html';
import { RowAlignment } from 'src/api/entities/sections/heading-rows/types';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * Compensators.
 */

const translateCompensator = (x: number) => (2 * Math.min(1 / 2, x) - 1) ** 6;
const opacityCompensator = (x: number) =>
  (1 + Math.cos(3 * Math.PI * (Math.max(1 / 6, Math.min(1 / 2, x + 1 / 12)) - 1 / 2))) / 2;

/**
 * Export `getEntryDirection` util.
 */

export const getEntryDirection = (align: RowAlignment, index: number, total: number) => {
  if (align !== 'center') {
    return align;
  }

  if (index === 0) {
    return 'top';
  }

  if (index === total) {
    return 'bottom';
  }
};

/**
 * `getTransform` util.
 */

const getTransform = (direction: EntryDirection, amount: number) => {
  switch (direction) {
    case 'left':
      return `translateX(-${amount}px)`;

    case 'right':
      return `translateX(${amount}px)`;

    case 'top':
      return `translateY(-${amount}px)`;

    case 'bottom':
      return `translateY(${amount}px)`;

    default:
      return;
  }
};

/**
 * `EntryDirection` type.
 */

type EntryDirection = ReturnType<typeof getEntryDirection>;

/**
 * `RowProps` type.
 */

type RowProps = PropsWithChildren<{ align: RowAlignment; direction: EntryDirection; progress?: number }>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Text).attrs({ variant: 'heading1' })`
  --heading-text-align: center;
  --heading-font-size: 56px;

  ${headingStyles}

  font-size: var(--heading-font-size);
  font-weight: 400;
  line-height: 1.1;
  overflow: visible;
  position: relative;
  text-align: var(--heading-text-align);

  ${media.min.ms`
     --heading-font-size: 104px;
  `}

  ${media.min.md`
    &[data-align='left'] {
      --heading-text-align: left;
    }

    &[data-align='right'] {
      --heading-text-align: right;
    }
  `}

  [data-font-size='medium'] & {
    --heading-font-size: 48px;

    ${media.min.ms`
      --heading-font-size: 70px;
    `}
  }
`;

/**
 * Export `Row` component.
 */

export const Row = forwardRef<HTMLSpanElement, RowProps>(({ align, children, direction, progress = 0 }, ref) => {
  const translation = 512 * translateCompensator(progress);
  const transform = getTransform(direction, translation);
  const opacity = opacityCompensator(progress);

  return (
    <Wrapper data-align={align} ref={ref}>
      <RawHtml element={'div'} style={{ opacity, transform }}>
        {children}
      </RawHtml>
    </Wrapper>
  );
});

/**
 * `Row` display name.
 */

Row.displayName = 'Row';
