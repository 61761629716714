/**
 * Module dependencies.
 */

import 'swiper/css';
import { Image } from 'src/components/core/image';
import { Link } from 'src/components/core/links';
import { LogosCarouselSectionFragment } from 'src/api/entities/sections/logos-carousel/types';
import { RawHtml } from 'src/components/core/raw-html';
import { RichText } from 'src/components/rich-text';
import { Section } from 'src/components/core/layout/section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Text } from 'src/components/core/text';
import { fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `LogosCarouselSectionProps` type.
 */

export type LogosCarouselSectionProps = LogosCarouselSectionFragment;

/**
 * `SwiperContainer` styled component.
 */

const SwiperContainer = styled(Swiper).attrs({
  direction: 'horizontal',
  grabCursor: true,
  slidesPerView: 'auto',
  spaceBetween: 8
})`
  --swiper-gradient-width: calc(var(--gutter) + var(--gutter-cards));

  left: calc(var(--swiper-gradient-width) * -1);
  margin: 0 var(--gutter-cards);
  padding: 0 var(--swiper-gradient-width);
  width: calc(100% + (var(--swiper-gradient-width) - var(--gutter-cards)) * 2);

  ::before,
  ::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 4%,
      rgba(255, 255, 255, 0.5046393557422969) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: var(--swiper-gradient-width);
    z-index: 2;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(180deg);
  }

  ${media.min.ms`
    &[data-swiper-center="true"] {
      .swiper-wrapper {
        justify-content: center !important;
      }
    }
  `}

  ${media.min.xl`
    --swiper-gradient-width: 90px;
    --swiper-container-edge-size: calc(((100vw - min(100vw, var(--container-width))) / 2) + var(--gutter) + var(--gutter-cards));

    left: calc(var(--swiper-container-edge-size) * -1);
    padding: 0 var(--swiper-container-edge-size);
    width: 100vw;
  `}
`;

/**
 * `StyledSwiperSlide` styled component.
 */

const StyledSwiperSlide = styled(SwiperSlide)`
  aspect-ratio: 200 / 176;
  width: 200px;

  ${media.min.ms`
    aspect-ratio: 1;
    min-width: 200px;
    width: calc((100% - 24px) / 4);
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  align-items: center;
  background-color: var(--color-neutral0);
  border-radius: var(--border-radius);
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 32px;
  width: 100%;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: grid;
  margin-top: 56px;
  row-gap: 56px;

  ${media.min.ms`
    margin: 88px 144px 0;
    row-gap: 88px;
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text).attrs({ as: 'div', variant: 'heading3' })`
  ${headingStyles}
  font-weight: 500;

  ${media.min.ms`
    max-width: 648px;
  `}
`;

/**
 * `Lead` styled component.
 */

const Content = styled(RichText)`
  ${textStyles.subtitle1}
  margin-left: auto;

  ${media.min.ms`
    max-width: 648px; e
  `}
`;

/**
 * `entryAnimation` constant.
 */

const entryAnimation = fadeInTopAnimation(0.15);

/*
 * Export `LogosCarouselSection` component.
 */

export function LogosCarouselSection({ items, lead, leadDescription, ...sectionProps }: LogosCarouselSectionProps) {
  return (
    <Section {...sectionProps} theme={'light'}>
      <motion.div {...entryAnimation}>
        <SwiperContainer data-swiper-center={items?.length <= 3}>
          {items.map(
            ({ asset, id, name, url }) =>
              asset?.url && (
                <StyledSwiperSlide key={id}>
                  <Card as={url ? Link : undefined} href={url}>
                    <Image alt={asset.alt ?? name} src={asset.url} style={{ aspectRatio: 1, objectFit: 'contain' }} />
                  </Card>
                </StyledSwiperSlide>
              )
          )}
        </SwiperContainer>
      </motion.div>

      {!isEmpty(lead) && !isEmpty(leadDescription) && (
        <ContentWrapper>
          <RawHtml element={Lead}>{lead}</RawHtml>

          <Content>{leadDescription as string}</Content>
        </ContentWrapper>
      )}
    </Section>
  );
}
