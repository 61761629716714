/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import {
  Description,
  ListSectionWrapper,
  StyledSectionHeading,
  Subtitle,
  Title
} from 'src/components/sections/lists/shared/styles';

import { List } from 'src/components/sections/lists/shared/list';
import { Marquee } from 'src/components/sections/lists/shared/marquee';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { SectionFooterCTAs } from 'src/components/core/layout/section-footer-ctas';
import { Text } from 'src/components/core/text';
import { TwoColumnsListSectionFragment } from 'src/api/entities/sections/two-columns-list/types';
import { fadeInLeftAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { scrollAnimations } from 'src/styles/animations';
import { stripTags } from 'src/core/utils/html-client';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & TwoColumnsListSectionFragment;

/**
 * `StyledMarquee` styled component.
 */

const StyledMarquee = styled(Marquee)`
  ${media.min.ms`
    transform: translateY(35%);
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 0;
  position: relative;

  ${media.min.ms`
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `RightColumn` styled component.
 */

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px 0;

  ${media.min.ms`
    padding: 0 32px;
  `}
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled.div`
  aspect-ratio: 1;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
`;

/**
 * `StyledTitle` styled component.
 */

const StyledTitle = styled(Title)`
  margin-bottom: 16px;
`;

/**
 * `StyledSubtitle` styled component.
 */

const StyledSubtitle = styled(Subtitle)`
  margin-bottom: 16px;
`;

/**
 * `StyledDescription` styled component.
 */

const StyledDescription = styled(Description)`
  margin-bottom: 16px;
`;

/**
 * Export `TwoColumnsListSection` component.
 */

export const TwoColumnsListSection = (props: Props) => {
  const {
    containerSize,
    description,
    footerCtas,
    footnote,
    isNumerical,
    items,
    media,
    sectionHeadings,
    subtitle,
    theme,
    title,
    ...sectionProps
  } = props;

  const [headingRef, headingPosition] = useViewportObserver<HTMLDivElement>({ threshold: 0.25 });
  const [imageRef, imagePosition] = useViewportObserver<HTMLDivElement>({ threshold: 0.25 });
  const { afterHeadingDelay, has, headingDelay } = useMemo(() => {
    const has = { description: !isEmpty(description), subtitle: !isEmpty(subtitle), title: !isEmpty(title) };
    const headingDelay = Object.values(has).filter(Boolean).length * 100;

    return {
      afterHeadingDelay: (headingDelay + 100) / 1000,
      has,
      headingDelay
    };
  }, [description, subtitle, title]);

  return (
    <ListSectionWrapper data-theme={theme ?? 'light'} {...sectionProps}>
      {!isEmpty(sectionHeadings) && <StyledSectionHeading {...sectionHeadings} data-has-marquee={!!title} />}

      <StyledMarquee text={stripTags(title)} />

      <Container size={containerSize}>
        <Grid>
          <AssetWrapper ref={imageRef}>
            <Media
              data-scroll={scrollAnimations.fadeInVertical}
              data-scroll-position={imagePosition}
              imageProps={{ fill: true, style: { inset: 0, position: 'absolute' } }}
              media={media}
            />
          </AssetWrapper>

          <RightColumn>
            <div ref={headingRef}>
              {has.title && (
                <RawHtml
                  data-scroll={scrollAnimations.blurFadeInVertical}
                  data-scroll-position={headingPosition}
                  element={StyledTitle}
                >
                  {title}
                </RawHtml>
              )}

              {has.subtitle && (
                <RawHtml
                  data-scroll={scrollAnimations.blurFadeInVertical}
                  data-scroll-delay={has.title ? 100 : undefined}
                  data-scroll-position={headingPosition}
                  element={StyledSubtitle}
                >
                  {subtitle}
                </RawHtml>
              )}

              {has.description && (
                <RawHtml
                  data-scroll={scrollAnimations.blurFadeInVertical}
                  data-scroll-delay={headingDelay - 100}
                  data-scroll-position={headingPosition}
                  element={StyledDescription}
                >
                  {description}
                </RawHtml>
              )}
            </div>

            <List delay={afterHeadingDelay} isNumerical={isNumerical} items={items} />

            {!isEmpty(footnote) && (
              <motion.div {...fadeInLeftAnimation(afterHeadingDelay)}>
                <Text as={'p'} fontWeight={400} variant={'paragraph2'}>
                  {footnote}
                </Text>
              </motion.div>
            )}
          </RightColumn>
        </Grid>

        {footerCtas && <SectionFooterCTAs {...footerCtas} />}
      </Container>
    </ListSectionWrapper>
  );
};
