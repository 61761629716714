/**
 * Module dependencies.
 */

import { MotionProps } from 'framer-motion';

/**
 * `getDefaultTransition` constant.
 */

const getDefaultTransition = (duration: number = 0.2): MotionProps['transition'] => ({
  damping: 11,
  duration
});

/**
 * `getCommon` function.
 */

const getCommon = (delay?: number, duration?: number) => ({
  transition: {
    ...getDefaultTransition(duration),
    ...(delay !== undefined && { delay })
  },
  viewport: { margin: '24px 0px', once: false }
});

/**
 * Export `fadeInLeftAnimation` constant.
 */

export const fadeInLeftAnimation = (delay?: number, left: number = 48): MotionProps => ({
  ...getCommon(delay),
  initial: { opacity: 0, x: -left },
  whileInView: { opacity: 1, x: 0 }
});

/**
 * Export `fadeInTopAnimation` constant.
 */

export const fadeInTopAnimation = (delay?: number, top: number = 48): MotionProps => ({
  ...getCommon(delay),
  initial: { opacity: 0, y: top },
  whileInView: { opacity: 1, y: 0 }
});

/**
 * Export `fadeInAnimation` constant.
 */

export const fadeInAnimation = (delay?: number): MotionProps => ({
  ...getCommon(delay, 0.4),
  initial: { opacity: 0 },
  whileInView: { opacity: 1 }
});

/**
 * Export `blurFadeInTopAnimation` constant.
 */

export const blurFadeInTopAnimation = (delay?: number, top: number = 48): MotionProps => ({
  ...getCommon(delay),
  initial: { filter: 'blur(16px)', opacity: 0, y: top },
  whileInView: { filter: 'blur(0px)', opacity: 1, y: 0 }
});
