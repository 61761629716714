/**
 * Module dependencies
 */

import 'swiper/css';
import { Autoplay, Navigation } from 'swiper/modules';
import { CardsCarouselSectionFragment } from 'src/api/entities/sections/cards-carousel/types';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Text } from 'src/components/core/text';
import { fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import { transparentize } from 'src/styles/utils/colors';
import { useRef } from 'react';
import arrowRightIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * `SlideContainer` styled component.
 */

const SlideContainer = styled(Swiper)`
  --section-case-studies-content-padding: 24px;

  ${media.min.ms`
    --section-case-studies-content-padding: 32px;
  `};

  background-color: var(--color-neutral95);
  border-radius: 16px;
  margin: 0 var(--gutter-cards) 48px;
  max-width: calc(var(--container-width) - ((var(--gutter) + var(--gutter-cards)) * 2));
  overflow: hidden;
  position: relative;
  width: calc(100vw - ((var(--gutter) + var(--gutter-cards)) * 2));

  ${media.min.ms`
    margin-bottom: 88px;
  `}
`;

/**
 * `NavigationWrapper` styled component.
 */

const NavigationWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  position: absolute;
  right: var(--section-case-studies-content-padding);
  top: var(--section-case-studies-content-padding);
  z-index: 1;
`;

/**
 * `NavigationButton` styled component.
 */

const NavigationButton = styled(IconButton).attrs({
  icon: arrowRightIcon,
  size: 'large',
  variant: 'neutral'
})`
  [data-theme='light'] &[data-variant='neutral'],
  [data-theme='dark'] &[data-variant='neutral'] {
    --button-background-color: var(--color-neutral80);
    --button-text-color: var(--color-primaryForDark20);
    --button-active-background-color: var(--color-neutral90);
    --button-active-text-color: var(--color-primaryForDark20);
    --button-hover-background-color: var(--color-neutral90);
    --button-hover-text-color: var(--color-primaryForDark20);
    --button-disabled-background-color: ${transparentize('neutral95', 0.6)};
    --button-disabled-text-color: var(--color-neutral80);
  }
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  height: 100%;
  padding: var(--section-case-studies-content-padding);
  position: relative;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  position: relative;
`;

/**
 * `Heading` styled component.
 */

const Heading = styled(Text).attrs({ as: 'div', fontWeight: 700, variant: 'heading3' })`
  ${headingStyles}

  align-items: center;
  color: var(--color-neutral0);
  margin-bottom: 48px;
  padding-right: 124px;

  ${media.min.ms`
    margin-bottom: 80px;
  `}
`;

/**
 * `Column` styled component.
 */

const Column = styled.div`
  ${media.min.ms`
    max-width: calc(50% - var(--gutter));
  `}
`;

/**
 * `BackgroundMedia` styled component.
 */

const BackgroundMedia = styled(Media)`
  inset: 0;
  position: absolute;

  &::after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.56) 45.81%, rgba(0, 0, 0, 0) 100%);
    content: '';
    inset: 0;
    position: absolute;
  }
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  ${textStyles.paragraph1}

  color: var(--color-neutral0);

  p {
    margin-bottom: 16px;
  }

  .heading {
    ${textStyles.subtitle2}
  }

  .heading,
  .highlight {
    color: var(--color-primaryForDark20);
  }
`;

/**
 * `entryAnimation` constant.
 */

const entryAnimation = fadeInTopAnimation(0.15);

/**
 * `CardSlide` component.
 */

const CardSlide = ({ content, media, title }: CardsCarouselSectionFragment['items'][0]) => (
  <Card>
    <BackgroundMedia media={media} />

    <Content>
      <RawHtml element={Heading}>{title}</RawHtml>

      <Column>
        <StyledRawHtml>{content}</StyledRawHtml>
      </Column>
    </Content>
  </Card>
);

/**
 * Export `CardsCarouselSection` component.
 */

export function CardsCarouselSection({ items, ...sectionProps }: CardsCarouselSectionFragment) {
  const navigationPrevRef = useRef<HTMLElement>(null);
  const navigationNextRef = useRef<HTMLElement>(null);

  switch (items?.length) {
    case 0:
    case undefined:
      return null;

    case 1:
      return (
        <Section {...sectionProps} containerSize={'narrow'}>
          <SlideContainer as={motion.div} {...entryAnimation}>
            <CardSlide {...items?.[0]} />
          </SlideContainer>
        </Section>
      );

    default:
      return (
        <Section {...sectionProps} containerSize={'narrow'}>
          <motion.div {...entryAnimation}>
            <SlideContainer
              autoHeight
              autoplay={{ delay: 10000, disableOnInteraction: false }}
              modules={[Autoplay, Navigation]}
              navigation={{
                enabled: true,
                nextEl: navigationNextRef.current,
                prevEl: navigationPrevRef.current
              }}
              onBeforeInit={swiper => {
                if (typeof swiper.params.navigation === 'object') {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }
              }}
              slidesPerView={1}
            >
              <NavigationWrapper>
                <NavigationButton ref={navigationPrevRef} style={{ rotate: '180deg' }} />
                <NavigationButton ref={navigationNextRef} />
              </NavigationWrapper>

              {items.map(item => (
                <SwiperSlide key={item.id} style={{ backgroundColor: item?.media?.backgroundColor }}>
                  <CardSlide {...item} />
                </SwiperSlide>
              ))}
            </SlideContainer>
          </motion.div>
        </Section>
      );
  }
}
