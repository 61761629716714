/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { FooterCtaFields } from 'src/api/entities/footer-ctas/types';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { scrollAnimations } from 'src/styles/animations';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 48px;

  ${media.max.ms`
    text-align: center;
  `}

  ${media.min.ms`
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    padding-top: 104px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ variant: 'subtitle2' })`
  padding: 8px;

  ${media.min.ms`
    max-width: 504px;
  `}
`;

/**
 * Export `SectionFooterCTAs` component.
 */

export function SectionFooterCTAs({ ctas, description }: NonNullable<FooterCtaFields>) {
  const [ref, intersectionPosition] = useViewportObserver<HTMLDivElement>({ rootMargin: '120px 0px 0px 0px' });

  return (
    <Wrapper ref={ref}>
      {!isEmpty(description) && (
        <Description data-scroll={scrollAnimations.fadeInVertical} data-scroll-position={intersectionPosition}>
          {description}
        </Description>
      )}

      {ctas?.map(
        (cta, index) =>
          cta?.label &&
          cta?.href && (
            <Button
              {...omit(cta, 'label')}
              aria-label={cta.label}
              data-scroll={scrollAnimations.fadeIn}
              data-scroll-delay={index * 100 + 100}
              data-scroll-position={intersectionPosition}
              hasLinkIcon
              key={index + cta.label}
              size={'large'}
              variant={index === 0 ? 'primary' : 'secondary'}
            >
              {cta.label}
            </Button>
          )
      )}
    </Wrapper>
  );
}
