/**
 * Module dependencies.
 */

import { Country } from './types';
import { axiosUpholdInstance } from 'src/api/lib/uphold';

/**
 * Export `getCountries` request.
 */

export const getCountries = async (): Promise<Country[]> => {
  const response = await axiosUpholdInstance.get('countries');

  return response.data;
};
