/**
 * Module dependencies.
 */

import { Alert } from 'src/components/core/alert';
import { Card } from 'src/components/sections/dca-calculator/components/shared';
import { type ChartProps } from './chart';
import { Spinner } from 'src/components/core/spinner';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { Tooltip } from 'src/components/core/tooltip';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormat } from 'src/hooks/format/use-format';
import { useFormatAsset } from 'src/hooks/format/use-format-asset';
import { useFormatCurrency } from 'src/hooks/format/use-format-currency';
import { useMemo, useState } from 'react';
import { useTranslate } from 'src/context/i18n';
import dynamic from 'next/dynamic';
import infoCircle from 'src/assets/svgs/16/info-circle.svg';
import styled from 'styled-components';

/**
 * `DcaChart` dynamic import.
 */

const DcaChart = dynamic(() => import('./chart').then(mod => mod.DcaChart));

/**
 * Export `Props` type.
 */

export type Props = Pick<ChartProps, 'data' | 'labels'> & {
  isError?: boolean;
  isLoading?: boolean;
  lastMedian?: number;
};

/**
 * `Header` styled component.
 */

const Header = styled.div`
  display: flex;
  gap: 8px 24px;

  ${media.max.ms`
    flex-direction: column;
  `}

  ${media.min.lg`
    column-gap: 48px;
  `}
`;

/**
 * `Date` styled component.
 */

const Date = styled.div`
  align-items: center;
  display: flex;
  gap: 6px;
  padding-top: 8px;
`;

/**
 * `HeaderValues` styled component.
 */

const HeaderValues = styled.div`
  align-content: start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  ${media.max.ms`
    gap: 2px 24px;
  `}

  ${media.min.ms`
    grid-template-columns: repeat(2, max-content);
    padding-top: 4px;

    > * {
      padding: 1px 24px;
    }

    > *:nth-child(2n) {
      border-left: 1px solid var(--color-neutral80);
    }
  `}

  ${media.min.xl`
    padding-top: 8px;
  `}
`;

/**
 * `ValueWithTooltip` styled component.
 */

const ValueWithTooltip = styled.div`
  ${media.min.ms`
    align-items: start;
    display: flex;
    gap: 4px;
  `}
`;

/**
 * `TooltipContent` styled component.
 */

const TooltipContent = styled.div`
  display: grid;
  gap: 4px;

  ${media.max.ms`
    padding-top: 2px;
  `}
`;

/**
 * Export `DcaChartCard` component.
 */

export const DcaChartCard = ({ data = [], isError, isLoading, labels, lastMedian }: Props) => {
  const { t } = useTranslate();
  const { formatMonthNumeric, formatPercentage } = useFormat();
  const { formatAsset } = useFormatAsset();
  const { formatCurrency } = useFormatCurrency();
  const isDesktop = useBreakpoint('ms');
  const [hoverIndex, setHoverIndex] = useState<number>();
  const { totalPurchaseAmount, totalValue, totalValueAsset } = useMemo(() => {
    const datum = !isDesktop && hoverIndex !== undefined ? data[hoverIndex] : data.slice(-1)[0];

    return {
      totalPurchaseAmount: datum?.total,
      totalValue: datum?.value,
      totalValueAsset: lastMedian ? datum?.value / lastMedian : undefined
    };
  }, [data, hoverIndex, isDesktop, lastMedian]);

  const tooltipContent = (
    <TooltipContent>
      {totalValueAsset !== undefined && (
        <Text as={'div'} fontWeight={400} variant={'small'}>
          {formatAsset(totalValueAsset)}
        </Text>
      )}

      {totalPurchaseAmount !== undefined && totalValue !== undefined && (
        <Text as={'div'} fontWeight={700} variant={'small'}>
          {formatPercentage(totalValue / totalPurchaseAmount)}
        </Text>
      )}
    </TooltipContent>
  );

  return (
    <Card style={{ gap: 48 }}>
      {isError ? (
        <Alert message={t('dcaCalculator.errors.chart')} type={'error'} />
      ) : (
        !!data.length && (
          <>
            <Header>
              <Text fontWeight={700} variant={'subtitle2'}>
                {labels.title}
              </Text>

              {!isDesktop && (
                <Date>
                  <Text fontWeight={400} variant={'small'}>
                    {`${t('labels.date')}:`}
                  </Text>

                  <Text fontWeight={700} variant={'paragraph2'}>
                    {formatMonthNumeric((hoverIndex !== undefined ? data[hoverIndex] : data.slice(-1)[0])?.time)}
                  </Text>
                </Date>
              )}

              <HeaderValues>
                <Text fontWeight={400} variant={'paragraph2'}>
                  {isDesktop ? labels.total : (labels.totalShort ?? labels.total)}
                </Text>

                <Text fontWeight={400} variant={'paragraph2'}>
                  {labels.value}
                </Text>

                <Text fontWeight={700} style={{ color: 'var(--color-info30)' }} variant={'subtitle2'}>
                  {totalPurchaseAmount !== undefined && formatCurrency(totalPurchaseAmount)}
                </Text>

                <ValueWithTooltip>
                  <Text fontWeight={700} style={{ color: 'var(--color-primaryForDark)' }} variant={'subtitle2'}>
                    {totalValue !== undefined && formatCurrency(totalValue)}
                  </Text>

                  {isDesktop ? (
                    <Tooltip
                      placement={'right-end'}
                      triggerElement={<Svg color={'var(--color-neutral60)'} icon={infoCircle} size={'16px'} />}
                    >
                      {tooltipContent}
                    </Tooltip>
                  ) : (
                    tooltipContent
                  )}
                </ValueWithTooltip>
              </HeaderValues>
            </Header>

            <DcaChart data={data} labels={labels} setHoverIndex={setHoverIndex} />
          </>
        )
      )}

      <Spinner active={isLoading} backgroundColor={'var(--color-neutral95)'} />
    </Card>
  );
};
