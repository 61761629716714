/**
 * Module dependencies.
 */

import { Dropdown } from 'src/components/core/dropdown';
import { FormGroup } from 'src/components/core/forms/form-group';
import { Row } from './shared';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { formControlSizes, formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import { textStyles } from 'src/styles/typography';
import { useFormat } from 'src/hooks/format/use-format';
import { useMemo, useState } from 'react';
import calendarIcon from 'src/assets/svgs/20/calendar.svg';
import chevronDown from 'src/assets/svgs/24/chevron-down.svg';
import chevronLeft from 'src/assets/svgs/20/chevron-left.svg';
import chevronRight from 'src/assets/svgs/20/chevron-right.svg';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = {
  id: string;
  label: string;
  max?: Date;
  min?: Date;
  onChange: (date: Date) => void;
  suffix?: string;
  value: Date;
};

/**
 * `FormControl` styled component.
 */

const FormControl = styled.div`
  ${formControlStyles}
  ${formControlVariants.ghost}
  ${formControlSizes.small}

  align-items: center;
  display: flex;
  font-weight: 400;
  gap: 2px;
  justify-content: end;
  padding-right: calc(var(--input-padding-x) / 2);

  :hover {
    background-color: var(--color-neutral90);
  }
`;

/**
 * `Chevron` styled component.
 */

const Chevron = styled(Svg).attrs({ icon: chevronDown, size: '24px' })`
  color: var(--input-border-color);
  transition: var(--transition-default);
  transition-property: color, transform;

  [aria-expanded='true'] & {
    color: var(--input-focus-border-color);
    transform: rotate(180deg);
  }
`;

/**
 * `Button` styled component.
 */

const Button = styled.button`
  ${textStyles.paragraph3}

  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 48px;
  color: var(--color-neutral10);
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none !important;
  padding: 6px 8px;
  transition: var(--transition-default);
  transition-property: background-color, color;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: var(--color-neutral80);
  }

  &:disabled {
    color: var(--color-neutral60);
    pointer-events: none;
  }

  &[data-toggled='true'] {
    background-color: var(--color-primaryForDark);
    color: var(--color-neutral105);
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(4, 1fr);
`;

/**
 * `YearWrapper` styled component.
 */

const YearWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 24px;
`;

/**
 * `ChevronButtons` styled component.
 */

const ChevronButtons = styled.div`
  display: flex;
  gap: 16px;
  grid-column: 3 / 4;
  justify-content: center;

  > button {
    height: 20px;
    padding: 0;
    width: 20px;
  }
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  cursor: default;
  padding: 16px 16px 24px;
`;

/**
 * Export `MonthPicker` component.
 */

export const MonthPicker = ({ id, label, max, min, onChange, suffix, value }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState<'months' | 'years'>('years');
  const [yearPage, setYearPage] = useState(value.getFullYear());
  const now = useMemo(() => new Date(), []);
  const { formatMonthLong, formatMonthShort } = useFormat();

  const onRequestClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setTab('years');
      setYearPage(value.getFullYear());
    }, 250);
  };

  return (
    <FormGroup
      aria-expanded={isOpen}
      id={id}
      onClick={event => {
        event.stopPropagation();
        setIsOpen(isOpen => !isOpen);
      }}
      role={'button'}
      tabIndex={0}
    >
      <Row>
        <Svg color={'var(--color-neutral50)'} icon={calendarIcon} size={'20px'} />

        <Text as={'label'} fontWeight={700} htmlFor={id} variant={'paragraph2'}>
          {label}
        </Text>

        <FormControl>
          {[formatMonthLong(value), suffix].join(' ')}

          <Chevron />

          <Dropdown isOpen={isOpen} onRequestClose={onRequestClose} position={'full'} top={8}>
            <Content>
              {tab === 'months' && (
                <>
                  <YearWrapper>
                    <Button
                      onClick={event => {
                        event.stopPropagation();
                        setTab('years');
                      }}
                    >
                      <b>{yearPage}</b>
                    </Button>

                    <ChevronButtons>
                      <Button
                        aria-label={'Previous year'}
                        disabled={yearPage <= now.getFullYear() - 15 || (min && yearPage - 1 < min.getFullYear())}
                        onClick={event => {
                          event.stopPropagation();
                          setYearPage(yearPage => yearPage - 1);
                        }}
                      >
                        <Svg icon={chevronLeft} size={'20px'} />
                      </Button>

                      <Button
                        aria-label={'Next year'}
                        disabled={yearPage >= now.getFullYear() || (max && yearPage + 1 > max.getFullYear())}
                        onClick={event => {
                          event.stopPropagation();
                          setYearPage(yearPage => yearPage + 1);
                        }}
                      >
                        <Svg icon={chevronRight} size={'20px'} />
                      </Button>
                    </ChevronButtons>
                  </YearWrapper>

                  <Grid>
                    {Array.from({ length: 12 }).map((_value, index) => {
                      const date = new Date(yearPage, index, 1);
                      const month = formatMonthShort(date);

                      return (
                        <Button
                          aria-label={`Select ${month}`}
                          data-toggled={yearPage === value.getFullYear() && date.getMonth() === value.getMonth()}
                          disabled={date > now || (min && date <= min) || (max && date >= max)}
                          key={index}
                          onClick={event => {
                            event.stopPropagation();
                            onChange(date);
                            onRequestClose();
                          }}
                        >
                          {formatMonthShort(date)}
                        </Button>
                      );
                    })}
                  </Grid>
                </>
              )}

              {tab === 'years' && (
                <Grid>
                  {Array.from({ length: 16 }).map((_value, index) => {
                    const year = now.getFullYear() - 15 + index;

                    return (
                      <Button
                        aria-label={`Select ${year}`}
                        data-toggled={year === value.getFullYear()}
                        disabled={(min && year < min.getFullYear()) || (max && year > max.getFullYear())}
                        key={index}
                        onClick={event => {
                          event.stopPropagation();
                          setYearPage(year);
                          setTab('months');
                        }}
                      >
                        {year}
                      </Button>
                    );
                  })}
                </Grid>
              )}
            </Content>
          </Dropdown>
        </FormControl>
      </Row>
    </FormGroup>
  );
};
