/**
 * Module dependencies.
 */

import styled from 'styled-components';

/**
 * Export `Card` styled component.
 */

export const Card = styled.div`
  background-color: var(--color-neutral95);
  border-radius: var(--border-radius);
  color: var(--color-neutral0);
  display: grid;
  height: 100%;
  min-height: 480px;
  padding: 24px;
  position: relative;
  width: 100%;
`;
