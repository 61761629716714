/**
 * Module dependencies.
 */

import { useCallback, useMemo, useSyncExternalStore } from 'react';
import isEmpty from 'lodash/isEmpty';

/**
 * `Setter` type.
 */

type Setter<T> = ((prevState: T | null) => T | null) | T | null;

/**
 * `ReturnType`.
 */

type ReturnType<T> = [T | null, (setter: Setter<T>) => void];

/**
 * `dispatchStorageEvent` hook.
 */

function dispatchStorageEvent(key?: string | null, newValue?: string | null) {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
}

/**
 * `setLocalStorageItem` component.
 */

const setLocalStorageItem = (key: string, value: any) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

/**
 * `removeLocalStorageItem` component.
 */

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

/**
 * `getLocalStorageItem` component.
 */

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

/**
 * `useLocalStorageSubscribe` component.
 */

const useLocalStorageSubscribe = (callback: (this: Window, ev: StorageEvent) => any) => {
  window.addEventListener('storage', callback);

  return () => window.removeEventListener('storage', callback);
};

/**
 * Export `useLocalStorage` hook.
 */

export function useLocalStorage<T extends object | number | string>(key: string): ReturnType<T> {
  const store = useSyncExternalStore(
    useLocalStorageSubscribe,
    () => getLocalStorageItem(key),
    () => null
  );

  const parsedStore = useMemo(() => {
    try {
      return store ? (JSON.parse(store) as T) : null;
    } catch {
      removeLocalStorageItem(key);

      return null;
    }
  }, [key, store]);

  const setState = useCallback(
    (newValue: Setter<T>) => {
      const nextState = typeof newValue === 'function' ? newValue(parsedStore) : newValue;

      if (isEmpty(nextState)) {
        removeLocalStorageItem(key);
      } else {
        setLocalStorageItem(key, nextState);
      }
    },
    [key, parsedStore]
  );

  return [parsedStore, setState];
}
