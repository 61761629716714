/**
 * Module dependencies.
 */

import { CountrySubdivision } from './types';
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { Params, getCountrySubdivisions } from './get';

/**
 * Export `CountrySubdivisionsQueryOptions` type.
 */

export type CountrySubdivisionsQueryOptions = Partial<DefinedInitialDataOptions<CountrySubdivision[]>> & {
  params: Params;
};

/**
 * Export `getCountrySubdivisionsQueryKey` util.
 */

export const getCountrySubdivisionsQueryKey = (params: Params) => ['countries', params.country, 'subdivisions'];

/**
 * Export `useCountrySubdivisions` query hook.
 */

export function useCountrySubdivisions({ params, ...options }: CountrySubdivisionsQueryOptions) {
  return useQuery({
    queryFn: async () => await getCountrySubdivisions(params),
    queryKey: getCountrySubdivisionsQueryKey(params),
    ...options
  });
}
