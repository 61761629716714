/**
 * Module dependencies.
 */

import { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldSelectCustom } from 'src/api/entities/form/types';
import { Select } from 'src/components/core/forms/select';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `SelectCustom` field component.
 */

export const SelectCustom = ({ displayTemplate, id, select }: FormFieldSelectCustom) => {
  const {
    formState: { errors }
  } = useFormContext();

  const { t } = useTranslate();

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={select.name}
        render={({ field: { onBlur, onChange, ref, value } }) => (
          <Select
            error={errors[select.name]?.message as string}
            helpText={select.description}
            id={id}
            label={select.label}
            noResultsDescription={t('forms.fields.validation.noResults.description')}
            noResultsLabel={t('forms.fields.validation.noResults.title')}
            onBlur={onBlur}
            onChange={({ value }) => onChange(value)}
            options={select.options}
            placeholder={select.placeholder}
            ref={ref}
            required={select.isRequired}
            value={select.options.find(option => option.value === value) ?? null}
          />
        )}
        rules={{ required: select.isRequired && t('forms.fields.validation.required') }}
      />
    </div>
  );
};
