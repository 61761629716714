/**
 * Module dependencies.
 */

import { ComponentProps } from 'react';
import { media } from 'src/styles/media';
import FastMarquee from 'react-fast-marquee';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  color: var(--color-neutral20);
  display: inline-block;
  font-size: 300px;
  font-weight: 400;
  left: calc((100vw - 100%) / -2);
  line-height: 1.1;
  opacity: 0.15;
  overflow: hidden;
  position: relative;
  transform: translateY(33%);
  width: 100vw;

  [data-theme='dark'] & {
    color: var(--color-neutral60);
  }

  ${media.max.ms`
    font-size: 160px;
    transform: translateY(40%);
  `}
`;

/**
 * `StyledFastMarquee` styled component.
 */

const StyledFastMarquee = styled(FastMarquee)`
  overflow: hidden;

  & > * {
    padding-right: 80px;
  }
` as typeof FastMarquee;

/**
 * Export `Marquee` component.
 */

export function Marquee({
  className,
  style,
  text,
  ...rest
}: ComponentProps<typeof FastMarquee> & { text: string | undefined }) {
  if (!text) {
    return null;
  }

  return (
    <Wrapper className={className} style={style}>
      <StyledFastMarquee autoFill speed={45} {...rest}>
        {text}
      </StyledFastMarquee>
    </Wrapper>
  );
}
