/**
 * Module dependencies.
 */

import { Alert } from 'src/components/core/alert';
import { Card } from './components/shared';
import { DcaCalculatorResults } from './results';
import { DcaCalculatorSection as DcaCalculatorSectionProps } from 'src/api/entities/sections/dca/types';
import { DcaChartCard } from './components/chart-card';
import { DcaForm } from './components/form';
import { DcaProvider, useDca } from 'src/context/dca';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { useCryptoAssets } from 'src/api/entities/crypto-assets/hooks';
import { useDcaData } from 'src/api/entities/sections/dca/hooks';
import { useMemo } from 'react';
import { useSettings } from 'src/context/settings';
import { useTranslate } from 'src/context/i18n';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

/**
 * `ContentProps` type.
 */

type ContentProps = Pick<DcaCalculatorSectionProps, 'chart' | 'formFields' | 'results'>;

/**
 * Export `Totals` type.
 */

export type Totals = {
  totalPurchaseAmount?: number;
  totalValue?: number;
  totalValueAsset?: number;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  margin: 0 var(--gutter-cards);
  min-height: 540px;
  padding: 24px 0;

  ${media.min.md`
    grid-template-columns: 340px 1fr;
  `}
`;

/**
 * `Disclaimer` styled component.
 */

const Disclaimer = styled(Text).attrs({ fontWeight: 400, variant: 'small' })`
  color: var(--color-neutral40);
  padding: 0 calc(0px - var(--gutter-cards));
  width: 100%;

  ${media.min.md`
    grid-column: 2 / span 1;
    text-align: right;
  `}

  ${media.min.lg`
    padding: 0 32px;
  `}
`;

/**
 * `Content` component.
 */

const Content = ({ chart: chartLabels, formFields, results }: ContentProps) => {
  const { t } = useTranslate();
  const { currency, initialAmount } = useDca();
  const {
    data: allAssets,
    isError: isErrorAssets,
    isLoading: isLoadingAssets
  } = useCryptoAssets({
    blacklistCrypto: formFields.crypto.blacklist,
    blacklistCurrencies: formFields.currency.blacklist,
    currency,
    preferredCrypto: formFields.crypto.preferred,
    preferredCurrencies: formFields.currency.preferred
  });

  const { data: chart, isError: isErrorChart, isLoading: isLoadingChart } = useDcaData();

  const normalizedChart = useMemo(() => {
    if (!chart || initialAmount === '') {
      return;
    }

    let accumulatedValue = 0;
    const accumulatedCryptoValue = chart.history.map(({ price }) => {
      const value = new BigNumber(initialAmount).dividedBy(price).toNumber();

      accumulatedValue = new BigNumber(accumulatedValue).plus(value).toNumber();

      return accumulatedValue;
    });

    return {
      ...chart,
      history: chart?.history?.map(({ price, time }, index) => {
        // Crypto asset at the time of buy.
        const assetCryptoValue = accumulatedCryptoValue[index];
        const amount = new BigNumber(initialAmount).times(index + 1).toNumber();

        return {
          time,
          total: amount,
          value: assetCryptoValue * price
        };
      })
    };
  }, [chart, initialAmount]);

  const totals = useMemo<Totals>(() => {
    if (!normalizedChart) {
      return {};
    }

    const lastDatum = normalizedChart.history.slice(-1)[0];

    return {
      totalPurchaseAmount: lastDatum?.total,
      totalValue: lastDatum?.value,
      totalValueAsset: normalizedChart.lastMedian ? lastDatum?.value / normalizedChart.lastMedian : undefined
    };
  }, [normalizedChart]);

  if (isErrorAssets) {
    return (
      <div style={{ margin: '0 var(--gutter-cards)', padding: '24px 0' }}>
        <Card>
          <Alert message={t('dcaCalculator.errors.form')} type={'error'} />
        </Card>
      </div>
    );
  }

  return (
    <>
      <Wrapper>
        <DcaForm
          fields={{
            ...formFields,
            asset: { ...formFields.crypto, options: allAssets?.crypto ?? [] },
            currency: { ...formFields.currency, options: allAssets?.fiat ?? [] }
          }}
          isLoading={isLoadingAssets}
        />

        <DcaChartCard
          data={normalizedChart?.history}
          isError={isErrorChart}
          isLoading={isLoadingChart}
          labels={chartLabels}
          lastMedian={normalizedChart?.lastMedian}
        />

        {chartLabels?.disclaimer && <Disclaimer>{chartLabels.disclaimer}</Disclaimer>}
      </Wrapper>

      {!isErrorChart && !isErrorAssets && (
        <DcaCalculatorResults {...results} repeatOptions={formFields.repeat.options} totals={totals} />
      )}
    </>
  );
};

/**
 * Export `DcaCalculatorSection` component.
 */

export function DcaCalculatorSection({ chart, formFields, results, ...rest }: DcaCalculatorSectionProps) {
  const { globalSettings, locale } = useSettings();
  const defaultAsset = formFields?.crypto?.defaultValue;
  const defaultAmount = formFields?.currency?.defaultAmount;
  const defaultRepeatDays = formFields?.repeat?.defaultValue;
  const defaultDateStart = formFields?.start?.defaultValue;
  const defaultDateEnd = formFields?.end?.defaultValue;
  const currency = useMemo(() => {
    const region = globalSettings?.regions.find(({ code }) => code === locale.regionCode);

    return region?.currency;
  }, [globalSettings?.regions, locale.regionCode]);

  if (!defaultAsset || !defaultAmount || !currency || !defaultDateStart || !defaultDateEnd || !defaultRepeatDays) {
    return null;
  }

  return (
    <DcaProvider
      asset={defaultAsset}
      currency={currency}
      dateEnd={new Date(defaultDateEnd)}
      dateStart={new Date(defaultDateStart)}
      initialAmount={defaultAmount}
      repeatDays={defaultRepeatDays}
    >
      <Section {...rest} containerSize={'default'} theme={'dark'}>
        <Content chart={chart} formFields={formFields} results={results} />
      </Section>
    </DcaProvider>
  );
}
