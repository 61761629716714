/* eslint-disable id-length */

/**
 * Module dependencies.
 */

import { ButtonProps, CardButton } from 'src/components/core/buttons/card-button';
import { CSSProperties, useMemo } from 'react';
import { CardColSpan, CardHeight } from 'src/types/cards';
import { CardContent, TextContent } from './shared';
import { ContentCard } from 'src/api/entities/sections/cards/types';
import { Media } from 'src/components/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `desktopHeights` config.
 */

export const desktopHeights: Record<CardHeight, number | '100%'> = {
  L: 616,
  M: 520,
  S: 408,
  XS: 336,
  full: '100%'
};

/**
 * `mobileAspectRatios` config.
 */

export const mobileAspectRatios: Record<CardHeight, number | 'auto'> = {
  L: 10 / 16,
  M: 5 / 7,
  S: 1,
  XS: 'auto',
  full: 'auto'
};

/**
 * `mobileSizes` config.
 */

export const mobileSizes: Record<CardHeight, Record<CardColSpan, CardHeight>> = {
  L: { 1: 'L', 1.5: 'L', 2: 'L', 3: 'L' },
  M: { 1: 'M', 1.5: 'M', 2: 'M', 3: 'L' },
  S: { 1: 'S', 1.5: 'M', 2: 'M', 3: 'L' },
  XS: { 1: 'XS', 1.5: 'XS', 2: 'XS', 3: 'XS' },
  full: { 1: 'full', 1.5: 'full', 2: 'full', 3: 'full' }
};

/**
 * `contentWidths` config.
 */

const contentWidths: Record<CardColSpan, Pick<CSSProperties, 'width' | 'maxWidth'>> = {
  1: { width: '100%' },
  1.5: { maxWidth: '512px', width: '100%' },
  2: { maxWidth: 'calc(50% + 24px)', width: '400px' },
  3: { maxWidth: 'calc(50% - 32px)', width: '512px' }
};

/**
 * `ContentOverlay` styled component.
 */

const ContentOverlay = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;

  & > div {
    z-index: 1;
  }
`;

/**
 * Export `Content` constant.
 */

export const Content = (
  props: Omit<ContentCard & { shrinkFactor?: number }, 'cta' | 'gridPosition' | 'type'> & {
    cta?: Omit<ButtonProps, 'children'> & { label?: string };
  }
) => {
  const {
    content: { body, media, subtitle, title },
    cta,
    ctaType,
    layout: {
      colSpan = '1',
      height = 'S',
      horizontalAlign = 'left',
      verticalAlign = 'space-between',
      verticalAlignMobile
    },
    shrinkFactor = 1
  } = props;

  const isMobile = !useBreakpoint('ms');

  const style = useMemo(() => {
    if (height === 'full') {
      return { height: desktopHeights[height] };
    }

    if (isMobile) {
      const mobileSize = mobileSizes[height][colSpan];

      return { aspectRatio: mobileAspectRatios[mobileSize] };
    }

    return { minHeight: shrinkFactor * (desktopHeights[height] as number) };
  }, [colSpan, height, isMobile, shrinkFactor]);

  return (
    <CardContent style={style}>
      <Media imageProps={{ fill: true }} media={media} />

      <ContentOverlay
        style={{
          ...(!isMobile && horizontalAlign !== 'full' && contentWidths[colSpan]),
          ...(!isMobile && horizontalAlign === 'right' && { alignSelf: 'end' }),
          justifyContent: isMobile ? (verticalAlignMobile ?? verticalAlign) : verticalAlign
        }}
      >
        <TextContent body={body} subtitle={subtitle} title={title} />

        {cta?.href && (
          <div {...(ctaType === 'card' && { style: { display: 'none' } })}>
            <CardButton hasLinkIcon {...omit(cta, 'label')}>
              {cta.label}
            </CardButton>
          </div>
        )}
      </ContentOverlay>
    </CardContent>
  );
};
