/**
 * Module dependencies.
 */

import { PropsWithChildren, useRef, useState } from 'react';
import { Svg } from 'src/components/core/svg';
import { textStyles } from 'src/styles/typography';
import { useBodyScroll } from 'src/hooks/use-body-scroll';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import chevronSvg from 'src/assets/svgs/20/chevron-right.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --scroll-spy-mobile-height: calc(
    100svh -
      (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3)
  );

  background-color: var(--color-neutral95);
  color: var(--color-text);
  display: grid;
  grid-template-rows: 58px 1fr;
  height: var(--scroll-spy-mobile-height);
  inset: auto 0 calc(58px + var(--bottom-banner-height) - var(--scroll-spy-mobile-height));
  position: fixed;
  transition: bottom var(--transition-default);
  z-index: 1;

  ${textStyles.subtitle2}

  &[aria-expanded='true'] {
    bottom: 0;
  }
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 20px;
  padding: 0 24px;

  ${textStyles.subtitle2}
`;

/**
 * `ChevronIcon` styled component.
 */

const ChevronIcon = styled(Svg).attrs({ icon: chevronSvg, size: '20px' })`
  color: var(--color-neutral40);
  transform: rotate(-90deg);
  transition: transform var(--transition-default);

  [aria-expanded='true'] & {
    transform: rotate(90deg);
  }
`;

/**
 * `Scroller` styled component.
 */

const Scroller = styled.div`
  overflow-y: auto;
`;

/**
 * Export `MobileScrollSpyWrapper` component.
 */

export const MobileScrollSpyWrapper = ({ children, title }: PropsWithChildren<{ title: string }>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useDetectOutsideClick(ref, () => setIsOpen(false));
  useBodyScroll({ off: isOpen });

  return (
    <Wrapper aria-expanded={isOpen} data-theme={'dark'} ref={ref}>
      <Header onClick={() => setIsOpen(!isOpen)}>
        {title}

        <ChevronIcon />
      </Header>

      <Scroller onClick={() => setIsOpen(false)}>{children}</Scroller>
    </Wrapper>
  );
};
