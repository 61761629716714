/**
 * Module dependencies.
 */

import { AssetProps } from 'src/core/utils/assets';
import { CryptoAssets } from 'src/api/entities/crypto-assets/types';
import { getAssets } from './queries';
import { useQuery, useQueryClient } from '@tanstack/react-query';

/**
 * `Params` type.
 */

type Params = {
  blacklistCrypto?: string[];
  blacklistCurrencies?: string[];
  currency: string;
  preferredCrypto?: string[];
  preferredCurrencies?: string[];
};

/**
 * Export `getAssetsQueryKey`.
 */

export const getAssetsQueryKey = (params?: Params) => ['assets', params].filter(Boolean);

/**
 * `sortAssets` util.
 */

const sortAssets = (assets: AssetProps[], preferred: string[], blacklist: string[]) => {
  if (!preferred.length && !blacklist.length) {
    return assets;
  }

  const sorted = [];

  for (const assetCode of preferred) {
    const asset = assets.find(asset => asset.code.toLowerCase() === assetCode.toLowerCase());

    if (asset) {
      sorted.push(asset);
    }
  }

  for (const asset of assets) {
    if (!blacklist.includes(asset.code) && !preferred.includes(asset.code)) {
      sorted.push(asset);
    }
  }

  return sorted;
};

/**
 * Export `useCryptoAssets` hook.
 */

export function useCryptoAssets(params: Params) {
  return useQuery({
    queryFn: async () => {
      const {
        blacklistCrypto = [],
        blacklistCurrencies = [],
        currency,
        preferredCrypto = [],
        preferredCurrencies = []
      } = params;

      const { crypto, fiat } = await getAssets(currency);

      return {
        crypto: sortAssets(crypto, preferredCrypto, blacklistCrypto),
        fiat: sortAssets(fiat, preferredCurrencies, blacklistCurrencies)
      };
    },
    queryKey: getAssetsQueryKey(params)
  });
}

/**
 * Export `useCryptoAsset` hook.
 */

export function useCryptoAsset(code: string, type: 'crypto' | 'fiat' = 'crypto') {
  const queryClient = useQueryClient();
  const data = queryClient.getQueriesData({ exact: false, queryKey: getAssetsQueryKey() });
  const assets = data[0]?.[1] as CryptoAssets;

  return assets?.[type].find(asset => asset.code.toLowerCase() === code.toLowerCase());
}
