/**
 * Module dependencies.
 */

import { MonthPicker } from './month-picker';
import { useDca } from 'src/context/dca';
import { useDcaData } from 'src/api/entities/sections/dca/hooks';
import { useMemo } from 'react';

/**
 * Export `DateFieldsProps` type.
 */

export type DateFieldsProps = {
  end: {
    label: string;
  };
  start: {
    label: string;
  };
};

/**
 * Export `DcaFormDateFields` component.
 */

export const DcaFormDateFields = ({ end, start }: DateFieldsProps) => {
  const { dateEnd, dateStart, repeatDays, updateDcaState } = useDca();
  const { data: chart } = useDcaData();
  const totalSteps = useMemo(() => {
    const estimate = Math.ceil((dateEnd.getTime() - dateStart.getTime()) / (1000 * 60 * 60 * 24 * repeatDays));

    return chart?.history.length ?? estimate;
  }, [chart, dateEnd, dateStart, repeatDays]);

  return (
    <>
      <MonthPicker
        id={'start'}
        label={start.label}
        max={dateEnd}
        onChange={value => updateDcaState({ dateStart: value })}
        value={dateStart}
      />

      <MonthPicker
        id={'end'}
        label={end.label}
        min={dateStart}
        onChange={value => updateDcaState({ dateEnd: value })}
        suffix={`(x${totalSteps})`}
        value={dateEnd}
      />
    </>
  );
};
