/**
 * Module dependencies.
 */

import { CSSProperties, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldSelectCountriesStates } from 'src/api/entities/form/types';
import { Select } from 'src/components/core/forms/select';
import { useCountrySubdivisions } from 'src/api/uphold/country-subdivisions/query';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `SelectStates` field component.
 */

export const SelectStates = ({ displayTemplate, id, select }: FormFieldSelectCountriesStates) => {
  const {
    formState: { errors },
    watch
  } = useFormContext();

  const { t } = useTranslate();
  const valueCountry = watch(select.name);
  const { data, isLoading } = useCountrySubdivisions({
    enabled: !!valueCountry?.length,
    params: { country: valueCountry }
  });

  const options = useMemo(() => data?.map(state => ({ label: state.name, value: state.code })) ?? [], [data]);

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={select.stateField.name}
        render={({ field: { onBlur, onChange, ref, value } }) => (
          <Select
            disabled={!valueCountry?.length}
            error={errors[select.stateField.name]?.message as string}
            helpText={select.stateField.description}
            id={id}
            isFilterable
            isLoading={isLoading}
            label={select.stateField.label}
            name={select.name}
            noResultsDescription={t('forms.fields.validation.noResults.description')}
            noResultsLabel={t('forms.fields.validation.noResults.title')}
            onBlur={onBlur}
            onChange={({ value }) => onChange?.(value)}
            onLoad={onChange}
            options={options}
            placeholder={select.stateField.placeholder}
            ref={ref}
            required={select.isRequired}
            value={options.find(option => option.value === value) ?? null}
          />
        )}
        rules={{
          required: select.isRequired && (isLoading || !!options?.length) && t('forms.fields.validation.required')
        }}
      />
    </div>
  );
};
