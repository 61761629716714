/**
 * Module dependencies.
 */

import { CSSProperties, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldSelectCountries, FormFieldSelectCountriesStates } from 'src/api/entities/form/types';
import { Select } from 'src/components/core/forms/select';
import { useCountries } from 'src/api/uphold/countries/query';
import { useSettings } from 'src/context/settings';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `SelectCountries` field component.
 */

export const SelectCountries = ({
  displayTemplate,
  id,
  select
}: FormFieldSelectCountries | FormFieldSelectCountriesStates) => {
  const {
    formState: { errors },
    setValue
  } = useFormContext();

  const { t } = useTranslate();
  const { data, isLoading } = useCountries();
  const { userCountry } = useSettings();
  const options = useMemo(() => data?.map(country => ({ label: country.name, value: country.alpha2 })) ?? [], [data]);

  useEffect(() => {
    setValue(select.name, userCountry?.toUpperCase() ?? null);
  }, [select.name, setValue, userCountry]);

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={select.name}
        render={({ field: { onBlur, onChange, ref, value } }) => (
          <Select
            error={errors[select.name]?.message as string}
            helpText={select.description}
            id={id}
            isFilterable
            isLoading={isLoading}
            label={select.label}
            name={select.name}
            noResultsDescription={t('forms.fields.validation.noResults.description')}
            noResultsLabel={t('forms.fields.validation.noResults.title')}
            onBlur={onBlur}
            onChange={({ value }) => {
              if (select.type === 'countries_with_states') {
                setValue(select.stateField.name, null);
              }

              onChange(value);
            }}
            onLoad={onChange}
            options={options}
            placeholder={select.placeholder}
            ref={ref}
            required={select.isRequired}
            value={options.find(option => option.value === value) ?? null}
          />
        )}
        rules={{ required: select.isRequired && t('forms.fields.validation.required') }}
      />
    </div>
  );
};
