/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { ListSectionFragment } from 'src/api/entities/sections/list-section/types';
import { List as ListWrapper } from 'src/components/sections/lists/shared/styles';
import { RichTextClean } from 'src/components/rich-text/clean';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { fadeInAnimation, fadeInLeftAnimation } from 'src/core/constants/motion';
import { motion } from 'framer-motion';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  delay: number;
  isNumerical?: boolean;
  items: ListSectionFragment['items'];
};

/**
 * `ListItemContent` styled component.
 */

const ListItemContent = styled.span`
  position: relative;
  z-index: 1;
`;

/**
 * Export `List` component.
 */

export function List({ delay, isNumerical, items }: Props) {
  return (
    <ListWrapper>
      {items.map((item, index) => (
        <li key={index}>
          <motion.div {...fadeInLeftAnimation(delay)}>
            {isNumerical ? (
              <Text block color={'primary'} style={{ marginRight: 16 }} variant={'paragraph1'}>
                {`${index + 1}.`}
              </Text>
            ) : (
              <Svg color={'var(--color-primary)'} icon={arrowIcon} id={'svg-arrow'} size={'100%'} />
            )}

            <RichTextClean element={ListItemContent}>{item.content}</RichTextClean>
          </motion.div>

          {item.cta && (
            <motion.div {...fadeInAnimation(delay)}>
              <Button {...omit(item.cta, 'label')} hasLinkIcon style={{ margin: '8px 0 0 32px' }}>
                {item.cta.label}
              </Button>
            </motion.div>
          )}
        </li>
      ))}
    </ListWrapper>
  );
}
