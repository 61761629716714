/**
 * Module dependencies.
 */

import { Card, Props } from './shared';
import styled from 'styled-components';

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 8px;
  margin: 0 var(--gutter-cards);
`;

/**
 * Export `MobileStoryCards` component.
 */

export const MobileStoryCards = ({ items }: Pick<Props, 'items'>) => (
  <Grid>
    {items.map(item => (
      <Card item={item} key={item.id} />
    ))}
  </Grid>
);
