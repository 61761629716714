/**
 * Module dependencies.
 */

import { DcaData } from 'src/pages/api/dca-data';
import { axiosNextInstance } from 'src/api/lib/next';

/**
 * Export `getDcaData` request.
 */

export const getDcaData = async (searchParams: string): Promise<DcaData> => {
  const response = await axiosNextInstance.get(`dca-data?${searchParams}`);

  return response.data;
};
