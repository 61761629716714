/**
 * Module dependencies.
 */

import { CryptoAssets } from './types';
import { axiosNextInstance } from 'src/api/lib/next';

/**
 * Export `getAssets` request.
 */

export const getAssets = async (currency: string): Promise<CryptoAssets> => {
  const response = await axiosNextInstance.get('assets', { params: { currency } });

  return response.data;
};
