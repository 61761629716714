/**
 * Module dependencies.
 */

import { AlternateColumnsSectionFragment } from 'src/api/entities/sections/alternate-columns/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { fadeInLeftAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import styled from 'styled-components';

/**
 * Constants.
 */

const assetSize = 480;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 96px;
  padding-bottom: 48px;

  ${media.min.ms`
    gap: 120px;
    padding-bottom: 0;
  `}
`;

/**
 * `RowWrapper` styled component.
 */

const RowWrapper = styled.div`
  display: grid;
  gap: 24px;

  ${media.min.ms`
    gap: 40px;
    grid-template-areas: "content asset";
    grid-template-columns: repeat(2, 1fr);

    &[data-inverted="true"] {
      grid-template-areas: "asset content";
    }
  `}

  ${media.min.md`
    gap: 88px;
  `}
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled(motion.div)`
  ${media.max.ms`
    display: flex;
    justify-content: center;

    > * {
      height: auto;
      max-width: ${assetSize}px;
      width: 100%;
    }
  `}

  ${media.min.ms`
    grid-area: asset;
    min-height: 240px;
    position: relative;

    > * {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: top;
    }
  `}

  ${media.min.md`
    min-height: 424px;
  `}
`;

/**
 * `StyledMedia` styled component.
 */

const StyledMedia = styled(Media)`
  border-radius: var(--border-radius);
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-self: center;
  display: grid;
  gap: 8px;

  > *:nth-child(2) {
    margin-top: 16px;
  }

  ${media.min.ms`
    grid-area: content;
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text).attrs({ fontWeight: 400, variant: 'heading4' })`
  ${headingStyles}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ variant: 'subtitle2' })`
  ${headingStyles}
`;

/**
 * Export `AlternateColumnsSection` component.
 */

export function AlternateColumnsSection({ rows, ...sectionProps }: AlternateColumnsSectionFragment) {
  const isDesktop = useBreakpoint('ms');

  return (
    <Section {...sectionProps}>
      <Grid>
        {rows.map(({ content, isInverted, media, title }, index) => (
          <RowWrapper data-inverted={isInverted} key={index}>
            <AssetWrapper {...fadeInLeftAnimation()}>
              <StyledMedia
                imageProps={isDesktop ? { fill: true } : { height: assetSize, width: assetSize }}
                media={media}
              />
            </AssetWrapper>

            <Content>
              <motion.div {...fadeInLeftAnimation(0.2)}>
                <RawHtml element={Lead}>{title}</RawHtml>
              </motion.div>

              <motion.div {...fadeInLeftAnimation(0.4)}>
                <RawHtml element={Description}>{content}</RawHtml>
              </motion.div>
            </Content>
          </RowWrapper>
        ))}
      </Grid>
    </Section>
  );
}
