/**
 * Module dependencies.
 */

import { AnimatedWrapper } from 'src/components/core/card/animated';
import { AppCenterListSectionFragment } from 'src/api/entities/sections/app-center-list/types';
import { Button } from 'src/components/core/buttons/button';
import { CardButton } from 'src/components/core/buttons/card-button';
import { CardContent, CardWrapper, TextContent } from 'src/components/core/card/shared';
import { Image } from 'src/components/core/image';
import { Section } from 'src/components/core/layout/section';
import { SectionFootnote } from 'src/components/sections/shared/footnote';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { urlPathToParts } from 'src/core/utils/url';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from 'src/context/i18n';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import styled from 'styled-components';

/**
 * `mobilePageSize` constant.
 */

const mobilePageSize = 3;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 100%;
  margin: 0 var(--gutter-cards);
  padding-top: 16px;
  position: relative;

  ${media.min.ms`
    grid-template-columns: repeat(12, 1fr);
    padding-top: 0;
  `}

  ${fadedCards}
`;

/**
 * `StyledAnimatedWrapper` styled component.
 */

const StyledAnimatedWrapper = styled(AnimatedWrapper)`
  height: 100%;
  margin-bottom: var(--gutter);

  ${media.min.ms`
    grid-column: span 6;

    &[data-first-card='true'] {
      grid-column: span 12;
    }
  `}

  ${media.min.md`
    grid-column: span 4;
  `}
`;

/**
 * `StyledCardWrapper` styled component.
 */

const StyledCardWrapper = styled(CardWrapper)`
  height: 100%;

  > div {
    height: 100%;
    justify-content: space-between;
    padding: 32px 24px 24px;

    ${media.min.ms`
      padding: 16px 32px 32px;
    `}
  }
`;

/**
 * `AssetWrapper` styled component.
 */

const AssetWrapper = styled.div`
  height: 60px;
  margin-bottom: 84px;
  max-width: 200px;
  padding: 0 8px;
  width: 100%;

  [data-first-card='true'] & {
    margin-bottom: 212px;
  }

  ${media.min.ms`
    height: 132px;
    margin-bottom: 52px;
    max-width: 208px;
    padding: 16px 0;

    [data-first-card='true'] & {
      margin-bottom: 76px;
    }
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;

  > * {
    object-fit: contain;
    object-position: left center;
  }
`;

/**
 * `LoadMoreWrapper` styled component.
 */

const LoadMoreWrapper = styled.div`
  background: linear-gradient(transparent 0%, var(--color-background) 80%);
  display: flex;
  inset: auto -4px 0;
  justify-content: center;
  padding: 224px 0 16px;
  pointer-events: none;
  position: absolute;

  > button {
    pointer-events: all;
  }
`;

/**
 * Export `AppCenterListSection` component.
 */

export function AppCenterListSection(props: AppCenterListSectionFragment & { ctaLabel: string }) {
  const { ctaLabel, footnote, items, ...sectionProps } = props;
  const { t } = useTranslate();
  const { asPath } = useRouter();
  const isMobile = !useBreakpoint('ms');
  const [totalVisibleMobile, setTotalVisibleMobile] = useState(mobilePageSize);
  const resolveRoute = useCallback(
    (slug: string) => `/${[...urlPathToParts(asPath), ...urlPathToParts(slug)].join('/')}`,
    [asPath]
  );

  const { hasMore, totalVisible } = useMemo(() => {
    const totalVisible = isMobile ? totalVisibleMobile : items.length;
    const hasMore = isMobile && items.length > totalVisible;

    return { hasMore, totalVisible };
  }, [isMobile, items.length, totalVisibleMobile]);

  return (
    <Section {...sectionProps}>
      <Grid {...(hasMore && { style: { paddingBottom: 80 } })}>
        {items.slice(0, totalVisible).map(({ gridPosition, id, logo, slug, subtitle, title }, index) => (
          <StyledAnimatedWrapper
            colIndex={gridPosition.col}
            data-first-card={index === 0}
            data-theme={'inherit'}
            key={id}
          >
            <StyledCardWrapper data-grid-card>
              <CardContent>
                <div>
                  {logo && (
                    <AssetWrapper>
                      <StyledImage alt={logo.alt} src={logo.url} />
                    </AssetWrapper>
                  )}

                  <TextContent body={subtitle} style={{ marginBottom: 16 }} title={title} />
                </div>

                <CardButton hasLinkIcon href={resolveRoute(slug)} size={'large'} variant={'neutral'}>
                  {ctaLabel}
                </CardButton>
              </CardContent>
            </StyledCardWrapper>
          </StyledAnimatedWrapper>
        ))}

        {hasMore && (
          <LoadMoreWrapper>
            <Button
              icon={arrowIcon}
              iconStyle={{ transform: 'rotate(90deg)' }}
              onClick={() => setTotalVisibleMobile(total => total + mobilePageSize)}
              style={{ pointerEvents: 'all' }}
              variant={'ghost'}
            >
              {t('actions.showMore')}
            </Button>
          </LoadMoreWrapper>
        )}
      </Grid>

      {footnote && <SectionFootnote>{footnote}</SectionFootnote>}
    </Section>
  );
}
