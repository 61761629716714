/**
 * Module dependencies.
 */

import { AsssetFieldProps, DcaFormAssetField } from './fields/asset';
import { Card } from 'src/components/sections/dca-calculator/components/shared';
import { CurrencyFieldProps, DcaFormCurrencyField } from './fields/currency';
import { DateFieldsProps, DcaFormDateFields } from './fields/dates';
import { DcaFormRepeatField, RepeatFieldProps } from './fields/repeat';
import { Spinner } from 'src/components/core/spinner';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = {
  fields: DateFieldsProps & {
    asset: AsssetFieldProps;
    currency: CurrencyFieldProps;
    repeat: RepeatFieldProps;
  };
  isLoading?: boolean;
};

/**
 * `StyledCard` styled component.
 */

const StyledCard = styled(Card)`
  align-content: space-between;
  gap: 24px;

  > div {
    display: grid;
    row-gap: 24px;
  }
`;

/**
 * Export `DcaForm` component.
 */

export const DcaForm = ({ fields: { asset, currency, repeat, ...dates }, isLoading }: Props) => (
  <StyledCard data-theme={'dark'}>
    {!isLoading && (
      <>
        <div>
          <DcaFormAssetField {...asset} />

          <DcaFormCurrencyField {...currency} />
        </div>

        <div>
          <DcaFormRepeatField {...repeat} />

          <DcaFormDateFields {...dates} />
        </div>
      </>
    )}

    <Spinner active={isLoading} backgroundColor={'var(--color-neutral95)'} />
  </StyledCard>
);
