/**
 * Module dependencies.
 */

import { CardAsset, CardText, CardTextWrapper, CardWrapper, Props } from './shared';
import { fadeInTopAnimation } from 'src/core/constants/motion';
import styled from 'styled-components';

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 8px;
  margin: 0 var(--gutter-cards);
`;

/**
 * `MobileProcessCards` component.
 */

export const MobileProcessCards = ({ items }: Pick<Props, 'items'>) => (
  <Grid>
    {items.map((item, index) => (
      <CardWrapper key={item.id} {...fadeInTopAnimation(0.15 * index)}>
        <CardAsset {...item} />

        <CardTextWrapper>
          <CardText index={index} item={item} />
        </CardTextWrapper>
      </CardWrapper>
    ))}
  </Grid>
);
