/**
 * Module dependencies.
 */

import { RefObject, useEffect, useState } from 'react';

/**
 * Export `useScrollProgress` hook.
 */

export function useScrollProgress(refs: RefObject<Map<string, HTMLElement | null>>) {
  const [progresses, setProgresses] = useState(
    new Map<string, number>(Array.from(refs.current?.keys() ?? []).map(key => [key, 0]))
  );

  useEffect(() => {
    const handleScroll = () => {
      refs.current?.forEach((node, id) => {
        if (!node) {
          return;
        }

        const boundingRect = node.getBoundingClientRect();
        const newValue = 1 - (boundingRect.top / window.innerHeight + boundingRect.height / (2 * window.innerHeight));
        const normalizedValue = Math.max(0, Math.min(1, newValue));

        if (!(progresses.get(id) === normalizedValue)) {
          setProgresses(progresses => new Map(progresses.set(id, normalizedValue)));
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [progresses, refs]);

  return progresses;
}
