/**
 * Module dependencies.
 */

import { useIsomorphicLayoutEffect } from 'framer-motion';
import { useRef, useState } from 'react';

/**
 * Export `useScrollSpy` hook.
 */

export function useScrollSpy<T extends HTMLElement, Ref extends HTMLElement>(selector: string) {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const contentRef = useRef<Ref>(null);
  const selectorRef = useRef<T[]>([]);

  useIsomorphicLayoutEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const elements = contentRef.current.querySelectorAll(selector);

    selectorRef.current = Array.from(elements) as NonNullable<T[]>;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = selectorRef.current.indexOf(entry.target as T);

          if (entry.isIntersecting) {
            setActiveIndex(index);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    );

    selectorRef.current.forEach(elem => {
      if (elem) {
        observer.observe(elem);
      }
    });

    return () => {
      selectorRef.current.forEach(elem => {
        if (elem) {
          observer.unobserve(elem);
        }
      });
    };
  }, []);

  return { activeIndex, contentRef };
}