/**
 * Module dependencies.
 */

import { CardButton } from 'src/components/core/buttons/card-button';
import { CardWrapper, ExpandableWrapper, TextContent, getWrapperProps } from './shared';
import { Content } from './content';
import { ContentCard } from 'src/api/entities/sections/cards/types';
import { Media } from 'src/components/media';
import { media } from 'src/styles/media';
import { useMemo, useState } from 'react';
import arrowRight from 'src/assets/svgs/24/arrow-right.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ContentCard & {
  shrinkFactor?: number;
};

/**
 * `ExpandableButtonWrapper` styled component.
 */

const ExpandableButtonWrapper = styled.div`
  bottom: 0;
  padding: 24px;
  position: absolute;
  width: 100%;
  z-index: 2;

  ${media.min.md`
    padding: 32px;
  `}
`;

/**
 * `ExpandableContent` styled component.
 */

const ExpandableContent = styled.div`
  display: grid;
  gap: 24px;
  padding: 0 24px 80px;

  ${media.min.md`
    padding: 0 32px 96px;
  `}
`;

/**
 * `StyledMedia` styled component.
 */

const StyledMedia = styled(Media)`
  border-radius: 16px;
  left: 50%;
  max-height: 480px;
  position: relative;
  transform: translateX(-50%);
`;

/**
 * Export `ExpandableCard` component.
 */

export const ExpandableCard = ({ cta, expanded, layout: { colSpan = '1', ...layoutRest }, theme, ...rest }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const layout = { colSpan, ...layoutRest };
  const label = useMemo(() => expanded?.[isExpanded ? 'labelCollapse' : 'labelExpand'], [expanded, isExpanded]);

  return (
    <CardWrapper {...getWrapperProps(rest)} aria-expanded={isExpanded} data-expandable data-theme={theme}>
      <Content {...rest} layout={layout} />

      <ExpandableButtonWrapper>
        <CardButton
          icon={arrowRight}
          iconStyle={{
            transform: `rotate(${isExpanded ? -90 : 90}deg)`,
            transition: 'transform var(--transition-default)'
          }}
          onClick={() => setIsExpanded(!isExpanded)}
          size={'small'}
        >
          {label}
        </CardButton>
      </ExpandableButtonWrapper>

      <ExpandableWrapper>
        <div style={{ overflow: 'hidden' }}>
          <ExpandableContent>
            <TextContent {...expanded} />

            <StyledMedia media={expanded?.media} />

            {cta?.label && (
              <CardButton
                {...omit(cta, 'label')}
                hasLinkIcon
                onClick={event => event.stopPropagation()}
                stretch
                variant={'primary'}
              >
                {cta.label}
              </CardButton>
            )}
          </ExpandableContent>
        </div>
      </ExpandableWrapper>
    </CardWrapper>
  );
};
