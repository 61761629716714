/**
 * Module dependencies.
 */

import { BlurredLinesSectionFragment } from 'src/api/entities/sections/blurred-lines/types';
import { Media } from 'src/components/media';
import { PropsWithChildren, forwardRef, useRef } from 'react';
import { Section, SectionProps } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { useScrollProgress } from 'src/hooks/use-scroll-progress';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & BlurredLinesSectionFragment;

/**
 * `getQuadratic` util.
 */

const getQuadratic = (x: number, yMin: number) => ((yMin - 1) / 0.25) * (x - 0.5) ** 2 + 1;

/**
 * `Leads` styled component.
 */

const Leads = styled.div`
  display: grid;
  gap: 48px;
  position: relative;
  width: 100%;

  ${media.max.ms`
    padding: 20px 0;
  `}
`;

/**
 * `Lead` component.
 */

const Lead = forwardRef<HTMLParagraphElement, PropsWithChildren<{ progress: number }>>(
  ({ children, progress }, ref) => (
    <Text
      as={'p'}
      ref={ref}
      style={{
        filter: `blur(${getQuadratic(progress, 6) - 1}px)`,
        opacity: getQuadratic(progress, 0),
        textAlign: 'center',
        transform: `translateY(${(0.5 - progress) * 64}px) scale(${getQuadratic(progress, 2 / 3)}) translate3d(0, 0, 0)`
      }}
      variant={'heading4'}
    >
      {children}
    </Text>
  )
);

/**
 * `Lead` display name.
 */

Lead.displayName = 'Lead';

/**
 * Export `BlurredLinesSection` component.
 */

export const BlurredLinesSection = (props: Props) => {
  const { items, media, ...rest } = props;
  const leadRefs = useRef<Map<string, HTMLParagraphElement | null>>(new Map());
  const progresses = useScrollProgress(leadRefs);

  return (
    <Section {...rest}>
      <Media media={media} style={{ height: 'auto', position: 'static' }} />

      <Leads>
        {items.map(
          ({ content, id }) =>
            id && (
              <Lead
                key={id}
                progress={progresses.get(id) ?? 0}
                ref={node => {
                  leadRefs.current.set(id, node);
                }}
              >
                {content}
              </Lead>
            )
        )}
      </Leads>
    </Section>
  );
};
