/**
 * Module dependencies.
 */

import { DisclaimerSectionFragment } from 'src/api/entities/sections/disclaimer/types';
import { Section } from 'src/components/core/layout/section';
import { SectionFootnote } from 'src/components/sections/shared/footnote';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = DisclaimerSectionFragment & { classname?: string };

/**
 * `StyledSection` styled component.
 */

const StyledSection = styled(Section)`
  padding-top: 0;
`;

/**
 * Export `DisclaimerSection` component.
 */

export const DisclaimerSection = (props: Props) => {
  const { disclaimer, id, textAlign, theme, ...rest } = props;

  return (
    <StyledSection data-theme={theme} id={id} {...rest}>
      <SectionFootnote textAlign={textAlign}>{disclaimer}</SectionFootnote>
    </StyledSection>
  );
};
