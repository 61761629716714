/**
 * Module dependencies.
 */

import { AnimatedWrapper } from './animated';
import { CardFragment } from 'src/api/entities/sections/cards/types';
import { CardWrapper, Props, getWrapperProps } from './shared';
import { Content } from './content';
import { ExpandableCard } from './expandable';
import { SubscriptionCard } from './subscription';
import { media } from 'src/styles/media';
import isEmpty from 'lodash/isEmpty';
import styled, { CSSProperties } from 'styled-components';

/**
 * `StyledAnimatedWrapper` styled component.
 */

const StyledAnimatedWrapper = styled(AnimatedWrapper)`
  ${media.min.ms`
    grid-column: span var(--col-span);
  `}
`;

/**
 * Export `Card` component.
 */

export const Card = ({ layout: { colSpan = '1', ...layoutRest }, theme = 'inherit', ...rest }: Props) => {
  const isExpandable = 'expanded' in rest && !isEmpty(rest.expanded);
  const layout = { colSpan, ...layoutRest };
  const cardProps = { ...rest, layout } as CardFragment;

  if (cardProps?.type === 'subscription') {
    return (
      <StyledAnimatedWrapper
        colIndex={rest?.gridPosition?.col}
        style={{ '--col-span': Number(colSpan) * 2 } as CSSProperties}
      >
        <SubscriptionCard {...cardProps} />
      </StyledAnimatedWrapper>
    );
  }

  return (
    <StyledAnimatedWrapper
      colIndex={rest?.gridPosition?.col}
      style={{ '--col-span': Number(colSpan) * 2 } as CSSProperties}
    >
      {isExpandable ? (
        <ExpandableCard {...cardProps} theme={theme} />
      ) : (
        <CardWrapper {...getWrapperProps(rest)} data-height={layout.height} data-theme={theme}>
          <Content {...cardProps} />
        </CardWrapper>
      )}
    </StyledAnimatedWrapper>
  );
};
