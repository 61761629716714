/**
 * Module dependencies.
 */

import { Row } from './shared';
import { Select } from 'src/components/core/forms/select';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { useDca } from 'src/context/dca';
import { useMemo } from 'react';
import repeatIcon from 'src/assets/svgs/20/repeat.svg';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type RepeatFieldProps = {
  label: string;
  options?: { days: number; id: string; label: string }[];
};

/**
 * `StyledSelect` styled component.
 */

const StyledSelect = styled(Select)`
  button:hover {
    background-color: var(--color-neutral90);
  }
`;

/**
 * Export `DcaFormRepeatField` component.
 */

export const DcaFormRepeatField = (props: RepeatFieldProps) => {
  const { repeatDays, updateDcaState } = useDca();
  const options = useMemo(
    () =>
      (props.options ?? []).map(span => ({
        label: span.label,
        value: span.days
      })),
    [props.options]
  );

  return (
    <Row
      onClick={event => {
        event.currentTarget?.querySelector('button')?.click();
      }}
    >
      <Svg color={'var(--color-neutral50)'} icon={repeatIcon} size={'20px'} />

      <Text as={'label'} fontWeight={700} htmlFor={'repeat'} variant={'paragraph2'}>
        {props.label}
      </Text>

      <StyledSelect
        id={'repeat'}
        onChange={({ value }) => updateDcaState({ repeatDays: Number(value) })}
        options={options}
        size={'small'}
        style={{ position: 'static' }}
        value={options.find(({ value }) => value === repeatDays)}
        variant={'ghost'}
      />
    </Row>
  );
};
